import { Injectable }              from '@angular/core';
import {Headers, Http, Response,RequestOptions} from '@angular/http';
import { Observable } from 'rxjs';
import { plutoRequestService } from './plutoRequest.service';


// import { Hero } from './hero';
@Injectable()
export class accountService {

  constructor (private https: Http, private pluto: plutoRequestService) {}

  getAirlineV0() {
      let accounts;

      // accounts = this.pluto.get('/core/users/' + id + '/accounts', {});

      return accounts;
  }

  getAirlineV1() {
      let accounts;

      accounts = this.pluto.get('/accounts/product/airline', {});

      return accounts;
  }

  getHotel() {
      let accounts;

      accounts = this.pluto.get('/accounts/product/hotel', {});

      return accounts;
  }

  getPPOB() {
      let accounts;

      accounts = this.pluto.get('/accounts/product/ppob', {});

      return accounts;
  }

    getAirlineRequirements(airlineVersion ?: number) {
        let opt = {use_api_version: false};
        let url = '/accounts/requirements';

        // if (!airlineVersion) {
        //     opt.use_api_version = false;
        //     url = '/core/account-requirement';
        // }


        return this.pluto.get(url, opt);
    }

    getHotelRequirements() {
        let name = 'hotel';
        let label = 'Hotel';

        return {
            'name': name,
            'label': label,
            'source': {
                'agencyCode': {
                      'type': 'string',
                      'required': 1,
                      'label': name+ " Agency Code",
                  },
                  'username': {
                      'type': 'string',
                      'required': 1,
                      'label': name+ " Username",
                  },
                  'password': {
                      'type': 'string',
                      'required': 1,
                      'label': name+ " Password",
                  },
                  'apiUrl': {
                      'type': 'select',
                      'required': 1,
                      'label': name+ " Api Url",
                      'options': {
                          'http://jarvisxml.mgbedbank.com/1.0': 'LIVE',
                          'http://uat-jarvis1-xmlsell.mgbedbank.com/1.0': 'UAT',
                      },
                  },
                  'providerId': {
                      'type': 'select',
                      'required': 1,
                      'label': name+ " Provider Id",
                      'options': {
                          'travflex': "travflex"
                      },
                  },
                // 'agentId': {
                //     'type': 'string',
                //     'required': 1,
                //     'label': name+" Agent Id",
                // },
                // 'username': {
                //     'type': 'string',
                //     'required': 1,
                //     'label': name+" Username",
                // },
                // 'password': {
                //     'type': 'string',
                //     'required': 1,
                //     'label': name+" Password",
                // },
                // 'internalCode': {
                //     'type': 'string',
                //     'required': 1,
                //     'label': name+" Internal Code",
                // },
                // 'apiUrl': {
                //     'type': 'select',
                //     'label': name+" Api Url",
                //     'required': 1,
                //     'options': {
                //         'http://xml.travflex.com/': 'Demo',
                //         'http://ws.mgholiday.com/': 'Live'
                //     },
                // },
                // 'providerId': {
                //     'type': 'select',
                //     'required': 1,
                //     'label': name+" Provider Id",
                //     'options': {
                //         'travflex': "travflex"
                //     },
                // },
            },
        };
    }

    getPPOBRequirements() {
        let name = 'ppob';
        let label = 'PPOB';

        return {
            'name': name,
            'label': label,
            'source': {
                'providerId': {
                    'type': 'select',
                    'required': 1,
                    'label': "Provider Id",
                    'options': {
                        'kopnus-iso': 'kopnus-iso'
                    },
                },
                'username': {
                    'type': 'string',
                    'required': 1,
                    'label': "Username",
                },
                'password': {
                    'type': 'string',
                    'required': 1,
                    'label': "Password",
                },
                'merchantType': {
                    'type': 'string',
                    'required': 1,
                    'label': "Merchant Type",
                },
                'caCode': {
                    'type': 'string',
                    'required': 1,
                    'label': "caCode",
                },
                'terminalId': {
                    'type': 'string',
                    'required': 1,
                    'label': "Terminal Id",
                },
                'merchantId': {
                    'type': 'string',
                    'required': 1,
                    'label': "Merchant Id",
                },
                'merchantLocation': {
                    'type': 'string',
                    'required': 1,
                    'label': "Merchant Location",
                },
                'institutionCode': {
                    'type': 'string',
                    'required': 1,
                    'label': "Institution Code",
                },
            },
        };
    }

}
