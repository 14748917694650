import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import {ROUTES} from "../../../app.routes";
import {TopnavbarComponent} from "./topnavbar.component";
import { PusherService } from '../../../services/pusher.service';

@NgModule({
    declarations: [TopnavbarComponent],
    imports     : [BrowserModule, RouterModule.forRoot(ROUTES)],
    exports     : [TopnavbarComponent],
    providers   : [PusherService],
})

export class TopnavbarModule {}
