import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {Injectable, Pipe, PipeTransform} from '@angular/core';
import { filterExcept } from './except.component';
import { searchPipe } from './search.component';

@NgModule({
    declarations: [ filterExcept, searchPipe],
    imports     : [BrowserModule],
    exports     : [ filterExcept,searchPipe]
})

export class pipeModule {}
