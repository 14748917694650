import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { flightComponent } from "./flight.component";
import { pipeModule } from '../../components/filter/pipe.module';
import { dataTablesCreator } from '../../services/datatables.service';
import { accountService } from '../../services/account.service';
import { airlinesService } from '../../services/airlines.service';
import { FileUploadModule } from 'ng2-file-upload';

import {reportModule} from "./report/report.module";

import {airlinesComponent} from "./airlines/airlines.component";
import {accountComponent} from "./account/account.component";
import {airportsComponent} from "./airports/airports.component";
import {faresComponent} from "./fares/fares.component";
import {tracksComponent} from "./tracks/tracks.component";
import {scrapelogsComponent} from "./scrapelogs/scrapelogs.component";

@NgModule({
    declarations: [flightComponent, airlinesComponent, accountComponent, airportsComponent, faresComponent, tracksComponent, scrapelogsComponent],
    imports     : [BrowserModule, RouterModule, pipeModule,DataTablesModule,ReactiveFormsModule, FormsModule, FileUploadModule, reportModule],
    providers   : [dataTablesCreator, accountService, airlinesService],
    exports     : [flightComponent, airlinesComponent, accountComponent, airportsComponent, faresComponent, tracksComponent, scrapelogsComponent],
})

export class flightModule {}
