import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import { FormsModule }   from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import {loginComponent} from "./login.component";
import {RouterModule} from "@angular/router";
import {ROUTES} from "../../app.routes";
import { sessionService } from '../../services/session.service';

@NgModule({
    declarations: [loginComponent],
    imports     : [BrowserModule,
                   FormsModule,
                   HttpModule,
                   JsonpModule,
                   RouterModule.forRoot(ROUTES)],
    providers: [sessionService],
})

export class LoginModule {}
