import { Injectable }              from '@angular/core';
import { plutoRequestService } from './plutoRequest.service';

@Injectable()

export class airlinesService {
  constructor(private pluto: plutoRequestService){
  }

  getAll() {
      return this.pluto.get('flight/api/routes/datatable',{use_api_version:true});
  }

  add(airline) {
      return this.pluto.post('maskapai/md_index', airline);
  }

    getAirlines(option ?: {
        countryId ?: string,
        country ?: string
    }) {
        let url = '/flight/api/airlines';

        if (typeof option != 'undefined') {
            url += '?';
            $.each(option, (key, value) => {
                url += `filter[where][${key}]=${value}&`;
            });
            url = url.substring(0, url.length - 1);
        }

        return this.pluto.get(url, {use_api_version:true});
    }


    getVersion(airlineName ?: string) {
        let versionZero = {
            'express': 0,
            'kalstar': 0,
            'trigana': 0,
            'sriwijaya': 0,
        };
        let versionOne = {
            'airasia': 1,
            'citilink': 1,
            'garuda': 1,
            'garudaInternational': 1,
            'gos': 1,
            'lion': 1,
            'sabre': 1,
            'sriwijayaApi': 1,
            'transnusa': 1,
            'xpress': 1,
        };
        let airlineZero = Object.keys(versionZero);
        let airlineOne = Object.keys(versionOne);
        let airlineAll = {};

        if (airlineName) {
            if (typeof versionZero[airlineName] != "undefined") {
                return versionZero[airlineName];
            } else if (typeof versionOne[airlineName] != "undefined") {
                return versionOne[airlineName];
            }
        }

        airlineZero.forEach((name) => {
            airlineAll[name] = 0;
        });
        airlineOne.forEach((name) => {
            airlineAll[name] = 1;
        });

        return airlineAll;
    }

}
