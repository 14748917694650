import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClientRouterConfig, ClientRoute } from '../../../models/client-route.models';
import { divisionRoutes } from '../../../modules/division.routes';
import { filterExcept } from '../../../components/filter/except.component';
import { sessionService } from '../../../services/session.service';
import { PusherService } from '../../../services/pusher.service';

declare var jQuery:any;

@Component({
    selector: 'navigation',
    templateUrl: 'navigation.template.html',
})

export class NavigationComponent {
    role:string;
    public routes: ClientRouterConfig;
    public userInfo: any;
    constructor(private router: Router,private session: sessionService) {
      // define it using a private method
      this.role = this.session.getRole();
      this.userInfo = this.session.getUserInfo();
      this.routes = this.findNavRoutes(divisionRoutes);
    }

    // define the private method
   private findNavRoutes(arrayOfRoutes: ClientRouterConfig) {
     let returnArray: ClientRouterConfig = [];
     arrayOfRoutes.forEach((route: ClientRoute) => {
       route.children.forEach((child: ClientRoute) => {
         if(child.data['roles'].indexOf(this.role) != -1) {
           returnArray.push(child);
         }
       });
     });
     return returnArray;
   }

    ngAfterViewInit() {
        jQuery('#side-menu').metisMenu();
        jQuery('.profile-element').click(function(){
          jQuery(this).toggleClass('open');
        });
        jQuery('.dropdown-notif').click(function(){
          jQuery('.dropdown-alerts').toggleClass('show');
        });
    }

    activeRoute(routename: string): boolean{
        return this.router.url.indexOf(routename) > -1;
    }


}
