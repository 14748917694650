import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { hotelsComponent } from "./hotels.component";
import { pipeModule } from '../../components/filter/pipe.module';
import { dataTablesCreator } from '../../services/datatables.service';
import { FileUploadModule } from 'ng2-file-upload';

import {dataHotelsComponent} from "./data-hotel/data-hotels.component";
import {hotelsScrapeComponent} from "./hotel-scrape/hotels-scrape.component";

@NgModule({
    declarations: [hotelsComponent, dataHotelsComponent, hotelsScrapeComponent],
    imports     : [BrowserModule, RouterModule, pipeModule,DataTablesModule,ReactiveFormsModule, FormsModule, FileUploadModule],
    providers   : [dataTablesCreator],
    exports     : [hotelsComponent, dataHotelsComponent, hotelsScrapeComponent],
})

export class hotelsModule {}
