import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { dataTablesCreator } from '../../../../services/datatables.service';

import { plutoRequestService } from '../../../../services/plutoRequest.service';

declare var jQuery:any;
declare var moment:any;

@Component({
    selector: 'report-issued',
    templateUrl: 'report-issued.template.html'
})
export class reportIssuedComponent implements OnInit, AfterViewInit{
  @ViewChild(DataTableDirective)
  public datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  dataClicked=[];
  dtOptions;
  userJson;
  userAlias = [];

  constructor(private dataTablesCreator: dataTablesCreator, private pluto: plutoRequestService){
    let ang = this;

    ang.pluto.get('/userApi',{}).subscribe(data => {
      ang.userJson = data.result;
      data.result.map(user => {
        ang.userAlias[user.group] = user.username;
      })
    })

  }

  tableCreator = [{
                    title: 'Booking Code',
                    data: 'bookingCode',
                    searchAble: true,
                    render: (data) => {return `<h3>${data}</h3>`},
                    className: 'text-center',
                  },
                  {
                    title: 'Client',
                    data: 'userId',
                    render: (data) => data ? this.userAlias[data] : '',
                    searchAble: true,
                    className: 'text-center',
                  },
                  {
                    title: 'Maskapai',
                    data: 'data.airline',
                    searchAble: true,
                    render: (data) => data ? `<b style="text-transform:capitalize;"> ${data} </b>` : '',
                    className: 'text-center'
                  },
                  {
                    title: 'Date',
                    data: 'createdAt',
                    render: (data) => {return `${moment(data).format('DD MMMM YYYY HH:mm')}`},
                  },
                  {
                    title: 'Print Ticket',
                    data: 'printTicket',
                    render: (data) => data ? '<span class="label label-primary">Yes</span>' : '<span class="label label-danger">No</span>',
                    className: 'text-center'
                  },
                  {
                    title: 'Ticket Number',
                    data: 'data.result.passengers[0].ticket_number',
                    render: (data) => data !== 'N/A' ? '<span class="label label-primary">Yes</span>' : '<span class="label label-danger">No</span>',
                    className: 'text-center'
                  },
                  {
                    title: 'Issued By',
                    data: 'issuedBy',
                    render: (data) => data ? `<h3 style="color:green"> ${data} </h3>` : '',
                    className: 'text-center'
                  },
                ];
  config = {
              api : '/reportIssuedFlight',
              table : this.tableCreator,
              dataClicked : this.dataClicked,
              primaryKey : 'id'
           }

 getInstance(datatableElement: DataTableDirective): void {
 datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
   {
     // this.table = dtInstance;
     const table = dtInstance;

    //  Search By
     const id = table.columns(0);
     $('#code').on( 'keyup', function () {
       id.search( this.value.toUpperCase() ).draw();
     });

     const client = table.columns(1);
     $('#scrapeModule').on( 'change', function () {
       client.search( this.value ).draw();
     });

     const maskapai = table.columns(3);
     $('#maskapai').on( 'keyup', function () {
       maskapai.search( this.value ).draw();
     });

     var detailRows = [];
     const $table = $('.dataTables-example');
     // let format = {
     //                 'title':{
     //                   'Ticket Number':'ticketNumbers[0]',
     //                   'Booking Date':'flightDetails.booking_date',
     //                   'Booking Agent':'flightDetails.booking_agent',
     //                   'Passenger Count':'passengerCount'
     //                 },
     //                 'tab':{
     //                   'Routes':'flightDetails.routes ? JSON.stringify(data.flightDetails.routes[0],null,4) : ""',
     //                   'Passengers':'flightDetails.passengers ? JSON.stringify(data.flightDetails.passengers[0],null,4) : ""',
     //                 },
     //                 'id':'id'
     //              }
     // this.dataTablesCreator.childCreator({ table  : table,
     //                                       $table : $table,
     //                                       button : '.btn-info',
     //                                       format : format
     //                                     })
   });
  }

  editForm(data,index,id){
    this.dataTablesCreator.editForm(this.dataClicked,data,index,id);
  }

  closeForm(index){
    $('#edit').attr('href','#'+index);
  }

  deleteForm(){
    let dataTablesCreator = this.dataTablesCreator;
    dataTablesCreator.delete(this.dataClicked);
    this.dataClicked.forEach(function(value,index,obj){
      $('td:contains('+value.id+')').val(value.id).parents('tr').removeClass('clicked').css('background-color','white');
    })
  }

  addForm(data){
    let trip = data.value.trip == "RT" ? true : false;
    delete data.value.trip;
    var dataCopy = data.value;
    data.value.active = data.value.active == "true" ? true : false;
    data.value.international = data.value.international == "true" ? true : false;
    data.value.popular = data.value.popular == "true" ? true : false;
    this.dataTablesCreator.add(data.value);
    if (trip){
      let destination = dataCopy.destination;
      dataCopy.destination = dataCopy.origin;
      dataCopy.origin = destination;
      this.dataTablesCreator.add(dataCopy);
    }
  }

  addCross(data){
    data.value.customUrl = '/1/flight/api/routes/cross-airport/';
    this.dataTablesCreator.add(data.value);
  }

  ngOnInit(){
    this.dtOptions = this.dataTablesCreator.config(this.config);
    jQuery('#date_added,#date_modified').datepicker();
  }

  ngAfterViewInit(){
    $('.instance').click();
  }

}
