import { ClientRouterConfig } from '../models/client-route.models';

import { flightComponent } from '../views/flight/flight.component';
import { airlinesComponent } from '../views/flight/airlines/airlines.component';
import { airportsComponent } from '../views/flight/airports/airports.component';
import { scrapelogsComponent } from '../views/flight/scrapelogs/scrapelogs.component';
import { tracksComponent } from '../views/flight/tracks/tracks.component';
import { faresComponent } from '../views/flight/fares/fares.component';
import { accountComponent } from '../views/flight/account/account.component';
import { reportBookingComponent } from '../views/flight/report/report-booking/report-booking.component';
import { reportIssuedComponent } from '../views/flight/report/report-issued/report-issued.component';
import { reportComponent } from '../views/flight/report/report.component';

import { loginComponent } from '../views/login/login.component';
import { registerComponent } from '../views/register/register.component';
import { mainViewComponent } from '../views/main-view/main-view.component';
import { basicComponent } from "../components/common/layouts/basic.component";
import { blankComponent } from "../components/common/layouts/blank.component";

import { hotelsComponent } from '../views/hotels/hotels.component';
import { dataHotelsComponent } from '../views/hotels/data-hotel/data-hotels.component';
import { hotelsScrapeComponent } from '../views/hotels/hotel-scrape/hotels-scrape.component';

import { shareComponent } from '../views/share-product/share.component';
import { topupComponent } from '../views/share-product/topup/topup.component';
import { transactionSP } from '../views/share-product/transaction/transaction.component';
import { transactionVendor } from '../views/share-product/transaction-vendor/transaction-vendor.component';
import { flightShareComponent } from '../views/share-product/report-flight/flight.component';
import { ppobComponent } from '../views/share-product/report-ppob/ppob.component';
import { hotelComponent } from '../views/share-product/report-hotel/hotel.component';
import { manageAccountComponent } from '../views/share-product/manage-account/manage-account.component';
  import { manageAccountClientComponent } from '../views/share-product/manage-account/client/manage-account-client.component';
  import { manageAccountProductComponent } from '../views/share-product/manage-account/product/manage-account-product.component';
  import { commissionProduct } from '../views/share-product/manage-account/commission/commission-product.component';

import { statisticLogErrorComponent } from '../views/statistic/log-error/st-log-error.component';
import { statisticLogBookingComponent } from '../views/statistic/log-booking/st-log-booking.component';
import { statisticLogSPComponent } from '../views/statistic/log-share-product/log-share-product.component';

import { AuthGuard }  from '../_guard/auth.guard';

export const divisionRoutes: ClientRouterConfig = [
  // App views
  {
    name: 'basic', path: '', component: basicComponent,
    children: [
      {
        name: 'Dashboard',
        path: 'mainView',
        component: mainViewComponent,
        canActivate: [AuthGuard],
        data: { roles: ['airbinder.owner','airbinder.staff','member.owner'], icon: 'fa fa-th-large' }
      },
      {
        name: 'User',
        path: 'user',
        component: accountComponent,
        canActivate: [AuthGuard],
        data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-users' }
      },
      {
        name: 'Flight',
        path: 'flight',
        component: flightComponent,
        canActivate: [AuthGuard],
        data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-plane' },
        children: [
          {
            name: 'default',
            path: '',
            redirectTo: 'airlines', pathMatch: 'full'
          },
          {
            name: 'Airlines',
            path: 'airlines',
            component: airlinesComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'], icon: 'fa fa-plane' }
          },
          // {
          //   name: 'Account',
          //   path: 'account',
          //   component: accountComponent,
          //   canActivate: [AuthGuard],
          //   data: { roles: ['airbinder.owner','member.owner'], icon: 'fa fa-user-circle' }
          // },
          {
            name: 'Airport',
            path: 'airport',
            component: airportsComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'], icon: 'fa fa-globe' }
          },
          {
            name: 'Route',
            path: 'route',
            component: tracksComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'], icon: 'fa fa-road' }
          },
          {
            name: 'Report Booking',
            path: 'reportBooking',
            component: reportBookingComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'airbinder.staff', 'member.owner'] }
          },
          // {
          //   name: 'Report Issued',
          //   path: 'reportIssued',
          //   component: reportIssuedComponent,
          //   canActivate: [AuthGuard],
          //   data: { roles: ['airbinder.owner', 'airbinder.staff'] }
          // },
          {
            name: 'Scrape Logs',
            path: 'scrapelogs',
            component: scrapelogsComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'airbinder.staff'], icon: 'fa fa-history' }
          },
          {
            name: 'Prices',
            path: 'prices',
            component: faresComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'airbinder.staff'], icon: 'fa fa-money' }
          },
        ]
      },
      {
        name: 'Hotels',
        path: 'hotels',
        component: hotelsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['airbinder.owner'], icon: 'glyphicon glyphicon-home' },
        children: [
          {
            name: 'default',
            path: '',
            redirectTo: 'dataHotels', pathMatch: 'full'
          },
          {
            name: 'Data Hotels',
            path: 'dataHotels',
            component: dataHotelsComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'] }
          },
          {
            name: 'Hotels Scrape',
            path: 'hotelScrape',
            component: hotelsScrapeComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'] }
          },
        ]
      },
      {
        name: 'Share Product',
        path: 'share',
        component: shareComponent,
        canActivate: [AuthGuard],
        data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-share-alt' },
        children: [
          {
            name: 'default',
            path: '',
            redirectTo: 'manage_account', pathMatch: 'full'
          },
          {
            name: 'Manage Account',
            path: 'manage_account',
            component: manageAccountComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-share-alt' },
            children: [
              {
                name: 'default',
                path: '',
                redirectTo: 'client', pathMatch: 'full'
              },
              {
                name: 'Client',
                path: 'client',
                component: manageAccountClientComponent,
                canActivate: [AuthGuard],
                data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-share-alt' }
              },
              {
                name: 'Product',
                path: 'product',
                component: manageAccountProductComponent,
                canActivate: [AuthGuard],
                data: { roles: ['airbinder.owner'], icon: 'fa fa-share-alt' }
              },
              {
                name: 'Commission',
                path: 'commission',
                component: commissionProduct,
                canActivate: [AuthGuard],
                data: { roles: ['airbinder.owner'], icon: 'fa fa-share-alt' }
              },
            ]
          },
          {
            name: 'Top Up',
            path: 'topup',
            component: topupComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-share-alt' }
          },
          {
            name: 'Transaction',
            path: 'transaction',
            component: transactionSP,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-share-alt' }
          },
          {
            name: 'Trans Vendor',
            path: 'trans_vendor',
            component: transactionVendor,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'], icon: 'fa fa-share-alt' }
          },
          {
            name: 'Report Flight',
            path: 'airline',
            component: flightShareComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-share-alt' }
          },
          {
            name: 'Report PPOB',
            path: 'ppob',
            component: ppobComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-share-alt' }
          },
          {
            name: 'Report Hotel',
            path: 'hotel',
            component: hotelComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner', 'member.owner'], icon: 'fa fa-share-alt' }
          },
        ]
      },
      {
        name: 'Statistic',
        path: 'statistic',
        component: shareComponent,
        canActivate: [AuthGuard],
        data: { roles: ['airbinder.owner'], icon: 'fa fa-bar-chart' },
        children: [
          {
            name: 'default',
            path: '',
            redirectTo: 'logError', pathMatch: 'full'
          },
          {
            name: 'Log Error',
            path: 'logError',
            component: statisticLogErrorComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'] }
          },
          {
            name: 'Booking',
            path: 'StatisticBooking',
            component: statisticLogBookingComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'] }
          },
          {
            name: 'Share Product',
            path: 'StatisticShareProduct',
            component: statisticLogSPComponent,
            canActivate: [AuthGuard],
            data: { roles: ['airbinder.owner'] }
          },
        ]
      },
    ]
  },
  {
    name: 'blank', path: '', component: blankComponent,
    children: [
      {
        name: 'Login',
        path: 'login',
        component: loginComponent,
        canActivate: [AuthGuard],
        data: { roles: ['auth'] }
      },
      {
        name: 'Register',
        path: 'register',
        component: registerComponent,
        canActivate: [AuthGuard],
        data: { roles: ['auth'] }
      }
    ]
  }
];
