import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import {RouterModule} from "@angular/router";
import {LocationStrategy, HashLocationStrategy} from '@angular/common';

import {ROUTES} from "./app.routes";
import { AppComponent } from './app.component';
import { DataTablesModule } from 'angular-datatables';

// App views
import {MainViewModule} from "./views/main-view/main-view.module";
import {flightModule} from "./views/flight/flight.module";
import {hotelsModule} from "./views/hotels/hotels.module";
import {LoginModule} from "./views/login/login.module";
import {RegisterModule} from "./views/register/register.module";
import {shareModule} from "./views/share-product/share.module";
import {statisticModule} from "./views/statistic/statistic.module";

import { AuthGuard }  from './_guard/auth.guard';
import { plutoRequestService } from './services/plutoRequest.service';
import { logRequestService } from './services/logRequest.service';
import { PusherService } from './services/pusher.service';

// App modules/components
import {LayoutsModule} from "./components/common/layouts/layouts.module";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // Angular modules
    BrowserModule.withServerTransition({ appId: 'standart-airbinder' }),
    HttpModule,
    FormsModule,
    JsonpModule,

    MainViewModule,
    flightModule,
    hotelsModule,
    LoginModule,
    RegisterModule,
    shareModule,
    statisticModule,

    // Modules
    LayoutsModule,

    RouterModule.forRoot(ROUTES),
    // DataTablesModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, AuthGuard, plutoRequestService, logRequestService, PusherService],
  bootstrap: [AppComponent]
})
export class AppModule { }
