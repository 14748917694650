import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
// import { Chart } from 'chart.js';
import { Chart, ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DatepickerOptions } from 'ng2-datepicker';

import { plutoRequestService } from '../../../services/plutoRequest.service';
import { environment } from '../../../../environments/environment';

declare var _:any;
declare var jQuery:any;
declare var moment:any;
declare var swal: any;
declare var toastr:any;

@Component({
    selector: 'st-log-error',
    templateUrl: 'st-log-error.template.html',
})
export class statisticLogErrorComponent implements OnInit, AfterViewInit {
    public barChartOptions: ChartOptions = {
      responsive: true,
      // We use these empty structures as placeholders for dynamic theming.
      scales: { xAxes: [{}], yAxes: [{}] },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      }
    };
    public barErrorLabels = [];
    public barErrorType: ChartType = 'bar';
    public barErrorLegend = true;
    public barErrorPlugins = [pluginDataLabels];
    public barErrorData: ChartDataSets[] = [
        {data:[], label:'Error Logs'}
      // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
      // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    ];

    public barAirlineLabels = [];
    public barAirlineType: ChartType = 'bar';
    public barAirlineLegend = true;
    public barAirlinePlugins = [pluginDataLabels];
    public barAirlineData: ChartDataSets[] = [
        {data:[], label:'Airline Logs'},
        {data:[], label:'Issued Logs'}
    ];

    public barActionLabels = [];
    public barActionType: ChartType = 'bar';
    public barActionLegend = true;
    public barActionPlugins = [pluginDataLabels];
    public barActionData: ChartDataSets[] = [
        {data:[], label:'Action Logs'},
    ];
    userJson;
    airlines = [];
    logs = [];
    actions = [];
    // providers;
    userId = '';
    fromDate = moment().format('MM/DD/YYYY');
    toDate = moment().format('MM/DD/YYYY');
    options: DatepickerOptions = {
        // minYear: 1970,
        // maxYear: 2030,
        displayFormat: 'DD MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        // minDate: new Date(Date.now()), // Minimal selectable date
        // maxDate: new Date(Date.now()),  // Maximal selectable date
        addClass: 'date-picker', // Optional, value to pass on to [ngClass] on the input field
        addStyle: {'text-align':'center', 'width':'280px'}, // Optional, value to pass to [ngStyle] on the input field
        fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    };

    constructor(private pluto : plutoRequestService){
      let ang = this;

      ang.pluto.get('/userApi',{}).subscribe(users => {
        ang.userJson = users.result
      })

    }

    ngOnInit():void {
      var ang = this;

      ang.getStatistic();
    }

    getStatistic(update = false) {
      var ang = this;
      var minDate = moment(ang.fromDate).toISOString();
      var maxDate = moment(ang.toDate).add(24, 'hours').toISOString();
      var queryDate = 'filter[where][userId]='+ang.userId+'&filter[where][createdAt][between][0]=' + minDate + '&filter[where][createdAt][between][1]=' + maxDate;
      ang.logs = [];
      ang.actions = [];


      ang.pluto.get('/scrapemodules',{}).subscribe(airlines => {
        airlines.map(airline => {
          ang.airlines[airline.name] = {};
          ang.airlines[airline.name]['airline'] = 0;
          ang.airlines[airline.name]['issued'] = 0;
          ang.airlines[airline.name]['color'] = ang.randomColorGenerator();
          ang.airlines[airline.name]['label'] = airline.label;
        })
        ang.pluto.get('/scrapeLogs/query?' + queryDate,{}).subscribe(logs => {
          logs.map(log => {
            // Count data by logs
            if (ang.logs[log.errorMessage.slice(0,15)]) {
              ang.logs[log.errorMessage.slice(0,15)].count++
            } else {
              ang.logs[log.errorMessage.slice(0,15)] = {};
              ang.logs[log.errorMessage.slice(0,15)]['count'] = 1;
              ang.logs[log.errorMessage.slice(0,15)]['label'] = log.errorMessage.slice(0,100);
            }

            // Count data by airlines
            ang.airlines[log.scrapeModuleId].airline++;
            if (log.action == 'issued') ang.airlines[log.scrapeModuleId].issued++;

            // Count data by action
            ang.actions[log.action] = typeof ang.actions[log.action] === 'undefined' ? 1 : ang.actions[log.action] + 1;
          })

            // Logs
            ang.barErrorLabels = _.keys(ang.logs);
            // ang.barErrorData[0].data = _.values(ang.logs);
            ang.barErrorData[0].data = _.values(ang.logs).map(data => data.count);

            // Airline
            // ang.barAirlineLabels = _.keys(ang.airlines);
            ang.barAirlineLabels = _.filter(_.values(ang.airlines), airlines => airlines.airline > 0).map(data => data.label);
            ang.barAirlineData[0].data = _.filter(_.values(ang.airlines), airlines => airlines.airline > 0).map(data => data.airline);
            ang.barAirlineData[1].data = _.filter(_.values(ang.airlines), airlines => airlines.airline > 0).map(data => data.issued);

            // Action
            ang.barActionLabels = _.keys(ang.actions);
            ang.barActionData[0].data = _.values(ang.actions);
        })
      })
    }

    private randomColorGenerator() {
      return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
    };

    chartClicked(event) {
      var ang = this;
      if (event.active[0] && event.active[0]._model) {
        var label = event.active[0]._model.label;
        // console.log(ang.logs[label].label)
        toastr.error(ang.logs[label].label);
      }
    }

    ngAfterViewInit(){
      let ang = this;

    }

}
