import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';

import { dataTablesCreator } from '../../../services/datatables.service';
// import { searchPipe } from '../../components/filter/search.component';

declare var jQuery:any;

@Component({
    selector: 'airlines',
    templateUrl: 'airlines.template.html'
})
export class airlinesComponent implements OnInit, AfterViewInit{
  @ViewChild(DataTableDirective)
  public datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  dataClicked=[];
  dtOptions;

  airline;
  search = {};

  constructor(private dataTablesCreator: dataTablesCreator,){}

  tableCreator = [{
                    title: 'No',
                    data: null,
                    orderable: true,
                    visible: false,
                    className: 'increment'
                  },
                  {
                    title: 'Airline',
                    data: 'name',
                    orderable: false,
                    searchAble: true,
                    render: (data) => `<h3>${data}</h3>`,
                  },
                  {
                    title: 'Label',
                    data: 'alias',
                    searchAble: true,
                    orderable: false,
                  },
                  {
                    title: 'Country',
                    data: 'country',
                    render: (data) => `<h3 style="color:#05846b">${data}</h3>`,
                    orderable: true,
                    searchAble: true
                  },
                  {
                    title: 'IATA',
                    data: 'iata',
                    className: 'text-center',
                    render: (data) => `<h4>${data}</h4>`,
                    orderable: false,
                  },
                  {
                    title: 'ICAO',
                    data: 'icao',
                    className: 'text-center',
                    render: (data) => `<h4>${data}</h4>`,
                    searchAble: true,
                    orderable: false,
                  },
                  {
                    title: 'Active',
                    data: 'active',
                    render: (data) => data ? '<h4 style="color:#05846b">Yes</h4>' : '<h4 style="color:red">No</h4>',
                    className: 'text-center',
                    // orderable: false,
                  },
                  // {
                  //   title: 'Details',
                  //   data: null,
                  //   defaultContent: '<button type="button" class="btn btn-primary btn-circle" title="View details"><i class="fa fa-unsorted"></i></button>',
                  //   orderable: false,
                  //   className: 'text-center'
                  // },
                ];
  config = {
              api : '/airlines',
              // clickHandler : 'self.someClickHandler(this.data)',
              table : this.tableCreator,
              dataClicked : this.dataClicked,
              primaryKey : 'name'
           }

 getInstance(datatableElement: DataTableDirective): void {
 datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
   {
     // this.table = dtInstance;
     const table = dtInstance;

    //  Search By
     const airline = table.columns(1);
     $('#airline').on( 'keyup', function () {
       airline.search( this.value ).draw();
     });

     const label = table.columns(2);
     $('#label').on( 'keyup', function () {
       label.search( this.value ).draw();
     });

     const iata = table.columns(3);
     $('#iata').on( 'keyup', function () {
       iata.search( this.value ).draw();
     });

     const icao = table.columns(4);
     $('#icao').on( 'keyup', function () {
       icao.search( this.value ).draw();
     });

    //  const status = table.columns(5);
    //  $('#status').on( 'keyup', function () {
    //    status.search( this.value ).draw();
    //  });

     var detailRows = [];
     const $table = $('.dataTables-example');
     let format = {
                     'title':{
                       'Airline Name':'name',
                       'Label':'alias',
                       'IATA CODE':'iata',
                       'ICAO CODE':'icao'
                     },
                     'tab':{
                       'Label':'alias',
                       'Active':'active',
                     },
                     'id':'increment'
                  }
     this.dataTablesCreator.childCreator({ table  : table,
                                           $table : $table,
                                           button : '.btn-circle',
                                           format : format
                                         })
   });

  }

  editForm(data,index,id){
    this.dataTablesCreator.editForm(this.dataClicked,data,index,id);
  }

  closeForm(index){
    $('#edit').attr('href','#'+index);
  }

  ngOnInit(){
    this.dtOptions = this.dataTablesCreator.config(this.config);
    jQuery('#date_added,#date_modified').datepicker();
  }

  idSearch(event:any){
    if (event.target.value === ''){
      delete this.search['airlineId'];
    } else {
      this.search['airlineId'] = event.target.value;
    }
  }

  airlineSearch(event:any){
    if (event.target.value === ''){
      delete this.search['name'];
    } else {
      this.search['name'] = event.target.value;
    }
  }

  labelSearch(event:any){
    if (event.target.value === ''){
      delete this.search['label'];
    } else {
      this.search['label'] = event.target.value;
    }
  }

  ngAfterViewInit(){
    $('.instance').click();
  }


}
