import { Component } from '@angular/core';

declare var jQuery:any;

@Component({
    selector: 'header-hotels',
    templateUrl: 'hotels.template.html'
})

export class hotelsComponent {}
