import { Component } from '@angular/core';
import { sessionService } from '../../services/session.service';

@Component({
    selector: 'mianView',
    templateUrl: 'main-view.template.html'
})
export class mainViewComponent {

    public userInfo: any;
    constructor(private session: sessionService) {
        this.userInfo = this.session.getUserInfo();
    }

}
