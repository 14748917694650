import { Component } from '@angular/core';

declare var jQuery:any;

@Component({
    selector: 'header-share',
    templateUrl: 'manage-account.template.html'
})

export class manageAccountComponent {}
