import { Component,OnInit,AfterViewInit,ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { dataTablesCreator } from '../../../services/datatables.service';
import { plutoRequestService } from '../../../services/plutoRequest.service';

declare var jQuery:any;
declare var swal: any;
declare var toastr:any;

const urlUpload = environment.URL_UPLOAD;

@Component({
    selector: 'data-hotels',
    templateUrl: 'data-hotels.template.html',
    styleUrls: ['data-hotels.css']
})
export class dataHotelsComponent implements OnInit, AfterViewInit {
  @ViewChild('nameEdit') name: ElementRef;
  @ViewChild('image') image: ElementRef;
  @ViewChild(DataTableDirective)
  public datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  dataClicked=[];
  dtOptions;
  inputHotel = {
    name: undefined,
    location: undefined,
    style: undefined,
    description: undefined,
  }
  inputConnectProvider = {
    hotelId: undefined,
    providerId: undefined,
    code: undefined,
  }

  airline;
  search = {};
  nameFile=[];
  filePreviewPath=[];
  hotelId = '';

  constructor(private dataTablesCreator: dataTablesCreator, private pluto : plutoRequestService, private sanitizer: DomSanitizer, private ElementRef:ElementRef){}

  public uploader:FileUploader = new FileUploader({url: urlUpload, itemAlias:'error'});
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }

  nameFolder(nama) {
    this.uploader.options.itemAlias = nama;
  }

  nameFolderEdit(){
    this.uploader.options.itemAlias = this.name.nativeElement.value;
  }

  removeImage(el){
    document.getElementById(el).remove()
  }

  tableCreator = [{
                    title: 'ID',
                    data: 'id',
                    orderable: true,
                    render: (data) => `<h5>${data}</h5>`,
                    className: 'text-center'
                  },
                  {
                    title: 'Hotel Name',
                    data: 'name',
                    render: (data) => `<h3 style="color:#05846b">${data}</h3>`,
                    orderable: true,
                    searchAble: true,
                    className: 'all'
                  },
                  {
                    title: 'Style',
                    data: 'style',
                    orderable: true,
                    searchAble: true,
                    className: 'text-center'
                  },
                  {
                    title: 'Location',
                    data: 'location',
                    searchAble: true,
                    render: (data) => data && data.includes(',') ? `<h4 style="color:#05846b">${data.join(', ')}</h4>` : data,
                    className: 'text-center all'
                  },
                  {
                    title: 'IMG',
                    data: 'image',
                    // render: (data) => {return `<img class="img-thumbnail" width="77px" src="${data[Math.floor(Math.random() * data.length)]}">`},
                    render: (data) => data.length ? `<h4 style="color:green"><i class="fa fa-check"></i></h4>` : `<h4 style="color:red"><i class="fa fa-remove"></i></h4>`,
                    className: 'text-center all'
                  },
                  // {
                  //   title: 'Details',
                  //   data: null,
                  //   defaultContent: '<button type="button" class="btn btn-outline btn-info dim" title="View details"><i class="fa fa-unsorted"></i></button>',
                  //   orderable: false,
                  //   className: 'text-center'
                  // },
                ];
  config = {
              api : '/hotel',
              clickHandler : this.someClickHandler,
              table : this.tableCreator,
              dataClicked : this.dataClicked,
              primaryKey : 'id'
           }

 getInstance(datatableElement: DataTableDirective): void {
 datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
   {
     // this.table = dtInstance;
     const table = dtInstance;

    //  Search By
     const airline = table.columns(1);
     $('#hotel').on( 'keyup', function () {
       airline.search( this.value ).draw();
     });

     const label = table.columns(3);
     $('#location').on( 'keyup', function () {
       label.search( this.value ).draw();
     });

     const iata = table.columns(2);
     $('#style').on( 'keyup', function () {
       iata.search( this.value ).draw();
     });

     const icao = table.columns(4);
     $('#icao').on( 'keyup', function () {
       icao.search( this.value ).draw();
     });

     var detailRows = [];
     const $table = $('.dataTables-example');

     this.dataTablesCreator.childCreator({ table  : table,
                                           $table : $table,
                                           button : '.btn-info',
                                           // format : format
                                         })
   });

  }

  someClickHandler(e,info: any,primaryKey): void {
    let checkIsset = false;
    if (typeof this.dataClicked.length !== 'undefined'){
      checkIsset = this.dataClicked.find( function( data ) {
        return data[primaryKey] === info[primaryKey];
      });
    }

    if (checkIsset) {
      this.dataClicked.forEach(function(value,index,obj){
            if (value[primaryKey] == info[primaryKey]){
              obj.splice(index, 1);
            }
      })
      $(e.target).parents('tr').removeClass('clicked').css('background-color','white');
    } else {
      if (Array.isArray(info.location)) info.location = info.location.join(',');
      this.dataClicked.push(info);
      $(e.target).parents('tr').addClass('clicked').css('background-color','#1ab3944d');
    }

  }

  addHotel(data){
    let dataSend = data.value;
    if (Object.keys(dataSend).find(data => dataSend[data] === null)) {
      toastr.error('Please check empty form');
      return false;
    }
    dataSend.location = dataSend.location.split(",");
    dataSend.image = this.nameFile;
    dataSend.customUrl = '/hotel';
    dataSend.callBack = 'id';
    dataSend.catchData = 'map'
    this.dataTablesCreator.add(dataSend).subscribe(
       suc => {
         $('.close').click().click()
         $('#connectProvider').click();
         this.hotelId = suc;
        //  $('input[name="hotelId"]').val(suc)
         $('input[name="providerId"]').focus()
         jQuery(".dataTables-example").dataTable().fnDraw();
       }
    );
    this.nameFile = [];
    this.uploader = new FileUploader({url: urlUpload, itemAlias:'error'});
    jQuery(".dataTables-example").dataTable().fnDraw();
  }

  connectProvider(data){
    let dataSend = data.value;
    console.log(dataSend)
    dataSend.method = 'post';
    // dataSend.customUrl = '/1/hotel/api/hotelcodeproviders/' + dataSend.providerId + '-' + dataSend.code;
    dataSend.customUrl = '/hotel/connect';
    this.dataTablesCreator.add(dataSend)
  }

  editForm(data,index,id){
    let image = this.image.nativeElement.getElementsByTagName('img');
    Object.keys(image).forEach(key => this.nameFile.push(image[key].getAttribute('src')));
    data.value.image = this.nameFile;
    data.value.location = data.value.location.split(",");
    this.dataTablesCreator.editForm(this.dataClicked,data,index,id);
    this.nameFile = [];
    jQuery(".dataTables-example").dataTable().fnDraw();
  }

  deleteForm(){
    var ang = this;
    this.dataClicked.forEach(function(hotel,iHotel,Obj){
      ang.pluto.delete('/hotel',{input:hotel}).subscribe(data => {
        toastr.success('Hotel ' + hotel.description + ' Deleted');
        jQuery(".dataTables-example").dataTable().fnDraw();
      })
    })
  }

  closeForm(index){
    $('#edit').attr('href','#'+index);
  }

  ngOnInit(){
    this.dtOptions = this.dataTablesCreator.config(this.config);
    let that = this;
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      var responsePath = JSON.parse(response);
      if (!responsePath.status){
        toastr.error(responsePath.msg);
      } else {
        toastr.success(responsePath.msg);
        that.nameFile.push(urlUpload+responsePath.generatedName);
      }
    };
    this.uploader.onAfterAddingFile = (fileItem) => {

      that.filePreviewPath[fileItem._file.name] = that.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
    }
  }

  ngAfterViewInit(){
    $('.instance').click();
  }


}
