import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {registerComponent} from "./register.component";
import {RouterModule} from "@angular/router";
import {ROUTES} from "../../app.routes";

@NgModule({
    declarations: [registerComponent],
    imports     : [BrowserModule,
                   RouterModule.forRoot(ROUTES)],
})

export class RegisterModule {}
