import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';

import { dataTablesCreator } from '../../../services/datatables.service';
import { plutoRequestService } from '../../../services/plutoRequest.service';

declare var jQuery:any;
declare var moment:any;
declare var toastr:any;

@Component({
    selector: 'fare',
    templateUrl: 'fares.template.html',
})
export class faresComponent implements OnInit, AfterViewInit {
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtTrigger = new Subject();
    dataClicked=[];
    inputClearFare = {
      scrapeModuleId: undefined,
      origin: undefined,
      destination: undefined,
    };
    scrapeModules=[];
    dtOptions;

    constructor(private dataTablesCreator : dataTablesCreator, private pluto: plutoRequestService){}

    tableCreator = [
                    {
                      title: 'Scrape Module',
                      data: 'scrapeModuleId',
                      searchAble: true,
                      render: (data) => data ? `<h3 style="color:#05846b; text-transform:capitalize;">${data}</h3>` : '',
                      className: 'text-center',
                    },
                    {
                      title: 'Basic',
                      data: 'basic',
                      orderable: false,
                      render: (data) => data ? 'Rp '+ `${data.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}` : '',
                    },
                    {
                      title: 'Adult',
                      data: 'adult',
                      orderable: false,
                      searchAble: true,
                      render: (data) => data ? 'Rp '+ `${data.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}` : '',
                    },
                    {
                      title: 'Child',
                      data: 'child',
                      orderable: false,
                      render: (data) => data ? 'Rp '+ `${data.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}` : '',
                    },
                    {
                      title: 'Infant',
                      data: 'infant',
                      orderable: false,
                      render: (data) => data ? 'Rp '+ `${data.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}` : '',
                    },
                    {
                      title: 'ID',
                      data: 'id',
                      orderable: false,
                      searchAble: true,
                      render: (data) => data ? `<h4>${data}</h4>` : '',
                      className: 'text-center',
                    },
                    {
                      title: 'Created',
                      data: 'createdAt',
                      visible: false,
                      render: (data) => {return `${moment(data).format('MM/DD/YYYY, HH:mm')}`},
                      searchAble: true
                    },
                    {
                      title: 'Modified',
                      data: 'updatedAt',
                      render: (data) => {return `${moment(data).format('MM/DD/YYYY, HH:mm')}`},
                      searchAble: true
                    },
                    {
                      title: 'Details',
                      data: null,
                      defaultContent: '<button type="button" class="btn btn-primary btn-circle" title="View details"><i class="fa fa-unsorted"></i></button>',
                      orderable: false,
                      className: 'text-center'
                    },
                  ];

    // button =  {
    //             text: 'Clear Fares',
    //             key: '1',
    //             action: function (e, dt, node, config) {
    //                 $('#clearFares').click();
    //             }
    //           }
    config = {
                api : '/fares',
                // button : this.button,
                table : this.tableCreator,
                dataClicked : this.dataClicked,
                primaryKey : 'id'
             }

    clearFares(data){
        var dataClear = {
          scrapeModuleId: data.value.scrapeModuleId,
          origin: data.value.origin,
          destination: data.value.destination
        };

        this.pluto.delete('/clearPrices',{input: dataClear}).subscribe(data => {
            if (Array.isArray(data)) {
              toastr.success(dataClear.scrapeModuleId +' Price Cache Clear');
              jQuery('.close').click().click();
            } else {
              toastr.error('Oops Something wrong');
            }
        })
    }

    getInstance(datatableElement: DataTableDirective): void {
    datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
      {
        // this.table = dtInstance;
        const table = dtInstance;

        //  Search By
         const orderId = table.columns(5);
         $('#order_id').on( 'keyup', function () {
           orderId.search( this.value ).draw();
         });

         const scrape = table.columns(0);
         $('#scrape').on( 'change', function () {
           scrape.search( this.value ).draw();
         });

        var detailRows = [];
        const $table = $('.dataTables-example');
        let format = {
                        'title':{
                          'Identity':'id',
                          'Route':'route[0]',
                          'Seat Class':'seatClass[0]',
                          'Flight Carier':'flightCarrier[0]'
                        },
                        'tab':{
                          'Origin':'origin',
                          'Destination':'destination',
                          'Expired':'expiredAt',
                        },
                        'id':'id'
                     }
        this.dataTablesCreator.childCreator({ table  : table,
                                              $table : $table,
                                              button : '.btn-circle',
                                              format : format
                                            })
      });

    }

    editForm(data,index,id){
      this.dataTablesCreator.editForm(this.dataClicked,data,index,id);
    }

    // clearFare(data){
    //   data = { scrapeModuleId:data.value.scrapeModuleId,origin:data.value.origin,destination:data.value.destination };
    //   this.dataTablesCreator.delete('clean-cache',data);
    // }

    closeForm(index){
      $('#edit').attr('href','#'+index);
    }

    ngOnInit():void {
      this.pluto.get('/scrapemodules?filter[where][active]=1',{}).subscribe(data => {
        this.scrapeModules = data;
      })

      this.dtOptions = this.dataTablesCreator.config(this.config);
      jQuery('#date_added,#date_modified').datepicker();
    }

    ngAfterViewInit(){
      $('.instance').click();
    }

}
