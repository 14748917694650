// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  API_URL: 'https://api-standart.airbinder.co.id/api',
  // API_URL: 'http://localhost:8000/api',
  API_LOG_URL: 'https://log-airline.airbinder.co.id/api',
  // API_LOG_URL: 'http://127.0.0.1:8000/api',
  // TOKEN_LOG: 'T24QKwvqX178oBRxNKFLsemg76x2rW89ssAfws7SDJOuzzusjUceJeBo1qncjTPrYLlvwutZo2F09YFkzVx1C5oUSfH3UzogOnt3',
  API_VERSION: 1,
  URL_UPLOAD: 'https://cdn.airbinder.co.id/',

  CACHE_DIALOG_TEMPLATE: true,
  PUSHER: {
    KEY: '2c78b9a1913eca04118f',
    CLUSTER: 'ap1'
  }
};
