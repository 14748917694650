import {Routes} from "@angular/router";
import {mainViewComponent} from "./views/main-view/main-view.component";
import { ClientRouterConfig, ClientRoute } from './models/client-route.models';
import { divisionRoutes } from './modules/division.routes';
import { AuthGuard }  from './_guard/auth.guard';


export const ROUTES:ClientRouterConfig = [
  // Main redirect
  {name: 'home', path: '', redirectTo: 'login', pathMatch: 'full', },

  ...divisionRoutes,

  // Handle all other routes
  {name: 'any', path: '**',    component: mainViewComponent }
];
