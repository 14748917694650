import { Component,OnInit,AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';

import { plutoRequestService } from '../../../../services/plutoRequest.service';
import { environment } from '../../../../../environments/environment';

declare var jQuery:any;
declare var moment:any;
declare var swal: any;
declare var toastr:any;

@Component({
    selector: 'commission-product',
    templateUrl: 'commission-product.template.html',
})
export class commissionProduct implements OnInit, AfterViewInit {
    providers;
    amount;
    type;
    commission;
    sort = 'ASC';

    constructor(private pluto : plutoRequestService){
      let ang = this;

      ang.pluto.get('/shareproduct/providers/commission',{}).subscribe(providers => {
        console.log(providers)
        ang.providers = providers;
      })
    }

    save(provider) {
      let ang = this;
      provider.providerId = provider.name;

      if (provider.name && provider.type && (provider.amount || provider.percentageOriginal)) {
        const where = 'where[providerId]=' + provider.providerId
        ang.pluto.post('/shareproduct/accounting/commission/upsertWithWhere?' + where, {input: provider}).subscribe(result => {
          toastr.success('Provider: '+ result.providerId + ', Type: ' + result.type + ', Amount: ' + result.amount + ', Original: ' + result.percentageOriginal + '%, Markup: ' + result.markup);
        })
      } else {
        toastr.error('Ooops, please check data input');
      }
    }

    sortData() {
      let ang = this;

      function compare(a,b) {
        if (a.productId < b.productId)
          return (ang.sort == 'DESC') ? -1 : 1;
        if (a.productId > b.productId)
          return (ang.sort == 'DESC') ? 1 : -1;
        return 0;
      }

      ang.providers.sort(compare);
      ang.sort = ang.sort == 'DESC' ? 'ASC' : 'DESC';
    }

    ngOnInit():void {

    }

    ngAfterViewInit(){

    }

}
