
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable }              from '@angular/core';
import {Headers, Http, Response,RequestOptions} from '@angular/http';
import { environment } from '../../environments/environment';




@Injectable()

export class logRequestService {
  private ENV = environment;
  private header;
  private userInfo;

  constructor(private http : Http){
  }

  /*
  * Send request to pluto.
  *
  * @param {string} method
  * @param {string} url
  * @param {object} opts
  * @return Promise
  */
  private sendReq(method, url, opts) {
     var API_LOG_URL;
     var header = this.getHeader();
     var input = typeof opts.input !== 'undefined' ? opts.input : null;
     var use_full_response = typeof opts.use_full_response !== 'undefined' ? opts.use_full_response : false;
     var options;

     // Default value for use_api_version
     if (typeof opts.use_api_version === 'undefined') {
         opts.use_api_version = false;
     }

     API_LOG_URL = this.ENV.API_LOG_URL;
     if (opts.use_api_version) {
         API_LOG_URL += '/'+this.ENV.API_VERSION ;
     }

     API_LOG_URL += url;

     // Delete unused options
     delete(opts.input);
     delete(opts.use_full_response);
     delete(opts.use_api_version);

     if (input !== null && header == null){
       options = input;
     } else if (input == null && header !== null){
       options = new RequestOptions({ headers: header });
     } else if (method == 'delete'){
       options = new RequestOptions({ headers: header, body: input});
     } else {
       options = new RequestOptions({ headers: header });
       return this.http[method](API_LOG_URL, input ,options)
       .map(this.extractData)
       .catch(this.handleError);
     }
     return this.http[method](API_LOG_URL, options)
             .map(this.extractData)
             .catch(this.handleError);
  }

  private extractData(res: Response) {
    let body = res.json();
    if (body && body.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(body));
    }
    return body || { };
  }

  private handleError (error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.content || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} : ${ err._body || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  /*
  * Make get request.
  *
  * @param {string} url
  * @param {object} opts
  * @return Promise
  */
   get(url, opts) {
     return this.sendReq('get', url, opts);
   }

  /*
  * Make post request.
  *
  * @param {string} url
  * @param {object} opts
  * @return Promise
  */
   post(url, opts) {
     return this.sendReq('post', url, opts);
   }

  /*
  * Make delete request.
  *
  * @param {string} url
  * @param {object} opts
  * @return Promise
  */
  delete(url, opts) {
    return this.sendReq('delete', url, opts);
  }

 /*
  * Make put request.
  *
  * @param {string} url
  * @param {object} opts
  * @return Promise
  */
  put(url, opts) {
    return this.sendReq('put', url, opts);
  }

  getToken(){
    return this.getUserInfo().token;
  }

  getUserInfo(){
    if (typeof localStorage.getItem('currentUser') !== "undefined" && localStorage.getItem('currentUser')) {
      this.userInfo = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      this.userInfo = '';
    }
    return this.userInfo;
  }

   private getHeader(){
     let headers = new Headers({ 'Content-Type': 'application/json','Authorization': 'Bearer ' +  this.getToken()});
     return headers;
   }
 }
