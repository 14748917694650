import { Component } from '@angular/core';

declare var jQuery:any;

@Component({
    selector: 'header-statistic',
    templateUrl: 'statistic.template.html'
})

export class statisticComponent {}
