import { Component, Input, Output, OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import { DatepickerOptions } from 'ng2-datepicker';

import { dataTablesCreator } from '../../../services/datatables.service';
import { plutoRequestService } from '../../../services/plutoRequest.service';

declare var jQuery:any;
declare var moment:any;
declare var swal: any;
declare var toastr:any;

@Component({
    selector: 'transaction',
    templateUrl: 'transaction-vendor.template.html',
})
export class transactionVendor implements OnInit, AfterViewInit {
    @ViewChild(DataTableDirective)
    vendor;
    userAlias=[];
    providers;
    userJson;
    providerId='kopnus-iso';
    account;
    userId=71;
    page=1;
    entries=[];
    show = {};
    dateTo = moment();
    dateFrom = moment();
    options: DatepickerOptions = {
        // minYear: 1970,
        // maxYear: 2030,
        displayFormat: 'DD MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        // minDate: new Date(Date.now()), // Minimal selectable date
        // maxDate: new Date(Date.now()),  // Maximal selectable date
        addClass: 'date-picker', // Optional, value to pass on to [ngClass] on the input field
        addStyle: {'text-align':'center', 'width':'280px'}, // Optional, value to pass to [ngStyle] on the input field
        fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    };

    constructor(private pluto : plutoRequestService){
      let ang = this;

      ang.pluto.get('/userApi',{}).subscribe(users=> {
        ang.userJson = users.result;
        users.result.map(user => {
          ang.userAlias[user.group] = user.username;
        })
        ang.pluto.get('/shareproduct/accounting/vendors',{}).subscribe(vendors => {
          ang.vendor = vendors;
          ang.pluto.get('/shareproduct/providers',{}).subscribe(providers => {
            ang.providers = providers;
            ang.setVendor();
            ang.getData();
          })
        })
      })


    }

    public setVendor(){
      let ang = this;

      ang.vendor.map(vendor => {
        ang.account = (vendor.userId == ang.userId && vendor.providerId == ang.providerId) ? vendor.accountId : ang.account;
      })
    }

    getData(page=1){
      var ang = this;
      const formatDateFrom = moment(this.dateFrom).format('MM/DD/YYYY');
      const formatDateTo   = moment(this.dateTo).format('MM/DD/YYYY');
      const dateFrom = moment(formatDateFrom).toISOString()
      const dateTo = moment(formatDateTo).add(1, 'days').toISOString()
      var parentUrl = '/shareproduct/accounting/accounts/'+ang.account+'/entries';
      var pageSkip = (page-1)*10;
      var filter = {
        where : {
          updatedAt : undefined,
        },
        order : 'updatedAt DESC',
        skip: undefined,
        limit: undefined,
      };

      filter.where.updatedAt = {lt : dateTo};

      let url = parentUrl + '?filter='+JSON.stringify(filter);

      ang.pluto.get(url,{}).subscribe(allAccounting => {
        filter.where.updatedAt = { between : [dateFrom, dateTo]};
        filter.skip = pageSkip;
        filter.limit = '10';

        let url = parentUrl + '?filter='+JSON.stringify(filter);

        ang.pluto.get(url,{}).subscribe(limitAccounting => {
          var debit  = 0;
          var credit = 0;
          var total = 0;

          if (limitAccounting.length){
            allAccounting.map((data,key) => {
              if (parseInt(key) < pageSkip) return;
              if (parseInt(data.amount) < 0) {
                credit += parseInt(data.amount)*-1;
              } else {
                debit += parseInt(data.amount);
              }
            })

            total = debit-credit;
            limitAccounting[0].debitAkhir = debit;
            limitAccounting[0].creditAkhir = credit;
            limitAccounting[0].saldo = total;
            limitAccounting.map((data,key) => {
              if (parseInt(data.amount) < 0) {
                credit -= parseInt(data.amount)*-1;
              } else {
                debit -= parseInt(data.amount);
              }

              const userId = parseInt(data.description.match(/(user.{4})/g)[0].slice(-2));
              limitAccounting[key].userName = this.userAlias[userId];
              limitAccounting[key].createdAt = moment(data.createdAt).format('HH:mm:ss, MMMM/DD/YYYY')
              total = total-parseInt(data.amount);
              if (parseInt(key) < limitAccounting.length-1) limitAccounting[parseInt(key)+1].saldo = total;
            })
          }

          limitAccounting[0].debitAwal = debit;
          limitAccounting[0].creditAwal = credit;

          ang.entries = limitAccounting;
          ang.page = page;
        })
      },
      (err) => { ang.entries = []; })

    }

    remove(data){
        var ang = this;
        var filter = {
          where : {
            transactionId : data.transactionId
          },
        };

        swal({
              title: "Are you sure to Rollback this transaction ?",
              text: "",
              type: "info",
              showCancelButton: true,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              // closeOnConfirm: true,
              // closeOnCancel: true
            }).then((result) => {
              if (result) {
                const sale = (data.description.search('Sale') > -1 || data.description.search('sale') > -1);
                const parentUrl = '/shareproduct/accounting/';
                const urlAdditional = '?filter='+JSON.stringify(filter);

                var url = parentUrl + (sale ? 'sales/' : 'deposits/');
                ang.pluto.get(url + urlAdditional,{}).subscribe(data => {
                  let urlRollback = url + data[0].id + '/rollback';
                  ang.pluto.delete(urlRollback,{}).subscribe(data => {
                    toastr.success('Rollback Success');
                    ang.getData(ang.page)
                  },
                  (err) => { return; })
                },
                (err) => { return; })
              }
            })

    }

    clear() {
      var ang = this;

      ang.providerId='';
      ang.userId= null;
      ang.dateTo = '';
      ang.dateFrom = '';
      $('.date-picker').val('');
      $('#userAlias').val('');
      $('#providers').val('');
    }


    ngOnInit():void {
      const ng = this;
      $('#dateFrom').on( 'change', function () {
        ng.dateFrom = this.value;
      });
      $('#dateTo').on( 'change', function () {
        ng.dateTo = this.value;
      });
      // jQuery('#dateFrom,#dateTo').datepicker().datepicker("setDate", new Date());
    }

    ngAfterViewInit(){

    }

}
