import { Injectable } from '@angular/core';
import {Headers, Http, Response,RequestOptions} from '@angular/http';
import { Observable } from 'rxjs';
import { plutoRequestService } from './plutoRequest.service';



// import { Hero } from './hero';
@Injectable()
export class sessionService {
  private role:string;
  private userInfo;
  // private serviceUrl = 'https://api.airbinder.co.id/core/login';  // URL to web API

  constructor (private https: Http, private pluto: plutoRequestService) {}

  login( email:string,password:string ) {
    return this.pluto.post('/login',{input:{email:email,password:password}})
  }

  logout(){
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserPluto');
  }

  getRole(){
    // return this.pluto.getUserInfo().role.split('.')[1];
    return this.pluto.getUserInfoPluto().role;
  }


    getUserInfo(){
        return this.pluto.getUserInfoPluto();
    }

}
