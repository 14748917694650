import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { shareComponent } from "./share.component";
import { topupComponent } from './topup/topup.component';
import { transactionSP } from './transaction/transaction.component';
import { transactionVendor } from './transaction-vendor/transaction-vendor.component';
import { manageAccountComponent } from './manage-account/manage-account.component';
import { manageAccountClientComponent } from './manage-account/client/manage-account-client.component';
import { manageAccountProductComponent } from './manage-account/product/manage-account-product.component';
import { commissionProduct } from './manage-account/commission/commission-product.component';
import { flightShareComponent } from './report-flight/flight.component';
import { ppobComponent } from './report-ppob/ppob.component';
import { hotelComponent } from './report-hotel/hotel.component';
import { pipeModule } from '../../components/filter/pipe.module';
import { CurrencyPipe } from '@angular/common';
import { dataTablesCreator } from '../../services/datatables.service';
import { MoneyFormatterDirective } from './money_formatter.directive';
import { MoneyPipe } from "../../components/filter/money.component";
import { NgDatepickerModule } from 'ng2-datepicker';

@NgModule({
    declarations: [shareComponent, topupComponent, transactionSP, transactionVendor, manageAccountComponent, manageAccountClientComponent, commissionProduct, manageAccountProductComponent, flightShareComponent, ppobComponent, hotelComponent, MoneyFormatterDirective, MoneyPipe],
    imports     : [BrowserModule, RouterModule, pipeModule,DataTablesModule,ReactiveFormsModule, FormsModule, NgDatepickerModule],
    providers   : [dataTablesCreator, MoneyPipe],
    exports     : [shareComponent, topupComponent, transactionSP, transactionVendor, manageAccountComponent, flightShareComponent, ppobComponent, hotelComponent, MoneyFormatterDirective, MoneyPipe],
})

export class shareModule {}
