import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

declare var jQuery:any;

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        jQuery('.modal').modal('hide');
        $('.modal-backdrop').remove('div');

        let roles = route.data["roles"] as Array<string>;
        let session = JSON.parse(localStorage.getItem('currentUserPluto'));

        if (roles.indexOf('auth') != -1 && session){
          this.router.navigate(['mainView']);
          return false;
        } else if (roles.indexOf('auth') != -1){
          return true;
        }

        // let userRole = session.role.split('.')[1];
        let userRole = session.role;

        return (session.username && roles == null || roles.indexOf(userRole) != -1);


        // not logged in so redirect to login page with the return url
        // this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
        // return false;
    }

}
