import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';

import { dataTablesCreator } from '../../../services/datatables.service';
import { plutoRequestService } from '../../../services/plutoRequest.service';
import { environment } from '../../../../environments/environment';

import { MoneyPipe } from "../../../components/filter/money.component";

declare var jQuery:any;
declare var moment:any;
declare var swal: any;
declare var toastr:any;

const BASE_URL = 'https://api-dev.airbinder.co.id';

@Component({
    selector: 'topup',
    templateUrl: 'topup.template.html',
})
export class topupComponent implements OnInit, AfterViewInit {
    @ViewChild(DataTableDirective)
    public datatableElement: DataTableDirective;
    private ENV = environment;
    dtTrigger = new Subject();
    idClient;
    userJson;
    dtInstance;
    userAlias = []
    banks;
    providers;
    minDate = '';
    maxDate = moment().format('MM/DD/YYYY');
    input = {
      userId: '',
      providerId: '',
      senderBankId: '',
      senderBankAccountNumber: 0,
      receiverBankId: '',
      receiverBankAccountNumber: 0,
      amount: '',
      amountVendor: '',
    }
    filter = {
      where: {
        status: undefined,
        createdAt: undefined,
        userId: undefined,
      }
    }
    options: DatepickerOptions = {
        // minYear: 1970,
        // maxYear: 2030,
        displayFormat: 'DD MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        // minDate: new Date(Date.now()), // Minimal selectable date
        // maxDate: new Date(Date.now()),  // Maximal selectable date
        addClass: 'date-picker', // Optional, value to pass on to [ngClass] on the input field
        addStyle: {'text-align':'center', 'width':'280px'}, // Optional, value to pass to [ngStyle] on the input field
        fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    };

    dtOptions;

    constructor(private dataTablesCreator : dataTablesCreator, private pluto : plutoRequestService, private moneyPipe: MoneyPipe){
      let ang = this;

      ang.input.amount = this.moneyPipe.transform('');
      ang.input.amountVendor = this.moneyPipe.transform('');

      ang.pluto.get('/userApi',{}).subscribe(users => {
        ang.userJson = users.result;
        users.result.map(user => {
          ang.userAlias[user.group] = user.username;
        })
      })

      ang.idClient = ang.pluto.getIdClient();

      ang.pluto.get('/shareproduct/banks',{}).subscribe(banks => {
        ang.banks = banks;
      })

      ang.pluto.get('/shareproduct/providers',{}).subscribe(providers => {
        ang.providers = providers;
      })

    }

    tableCreator = [{
                      title: 'ID',
                      data: 'id',
                      orderable: true,
                      // searchAble: true,
                      visible: false,
                      className: 'text-center',
                    },
                    {
                      title: 'userID',
                      data: 'userId',
                      className: 'text-center all',
                      searchAble: true,
                      render: (data) => data ? `<b style="text-transform:capitalize;"> ${this.userAlias[data]} </b>` : ''
                    },
                    {
                      title: 'Amount',
                      data: 'amount',
                      orderable: true,
                      render: (data) => {return 'Rp '+ `${data.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`},
                      searchAble: true,
                    },
                    {
                      title: 'Created at',
                      data: 'createdAt',
                      orderable: false,
                      searchAble: true,
                      render: (data) => {return `${moment(data).format('DD MMMM YYYY HH:mm')}`},
                    },
                    {
                      title: 'Updated at',
                      data: 'updatedAt',
                      render: (data) => {return `${moment(data).format('DD MMMM YYYY HH:mm')}`},
                    },
                    {
                      title: 'Status',
                      data: 'status',
                      render: (data) => data == 'unapproved' ? '<h3 style="color:yellow">Pending</h3>' : (data == 'approved' ? '<h3 style="color:green">Approved</h3>' : (data == 'rejected') ? '<h3 style="color:grey">Rejected</h3>' : '<h3 style="color:red">Error</h3>'),
                      className: 'text-center',
                      searchAble: true
                    },
                    {
                      title: 'Action',
                      data: 'status',
                      render: (data) => data == 'unapproved' ? '<button class="btn btn-sm btn-info btn-circle" title="approve" (click)="setApprove()" type="button"><i class="fa fa-check"></i></button> <button class="btn btn-sm btn-warning btn-circle" title="reject" (click)="setError()" type="button"><i class="fa fa-times"></i></button>' : '',
                      orderable: false,
                      className: 'text-center all'
                    },
                  ];
    config = {
                api : '/shareproduct/accounting/deposits',
                table : this.tableCreator,
                smButton : this.clickProcess,
                dataTablesCreator : this.dataTablesCreator,
                pluto : this.pluto,
             }

    getInstance(datatableElement: DataTableDirective): void {
    datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
      {
        const table = dtInstance;
        this.dtInstance = table;

         const amount = table.columns(2);
         $('#amount').on( 'keyup', function () {
           amount.search( this.value ).draw();
         });

         const status = table.columns(5);
         $('#status').on( 'change', function () {
           if ( this.value ) {
             status.search( this.value ).draw();
           } else {
             status.search( this.value ).draw();
           }
         });

         const action = table.column(6);
         if(this.idClient) {
           // client.search( ang.idClient ).draw();
           action.visible(false)
         }

      });

    }

    clickProcess(data,e){
      let that = this;
      let approve = $(e).hasClass('btn-info') || $(e).hasClass('fa-check');
      let reject = $(e).hasClass('btn-warning') || $(e).hasClass('fa-times');

      swal({
            title: 'Are you sure to ' + (approve ? 'approve ?' : 'reject ?'),
            text: '',
            type: approve ? 'info' : 'warning',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            // closeOnConfirm: true,
            // closeOnCancel: true
          }).then((result) => {
            if (result) {
              let url = '/shareproduct/accounting/deposits/' + data.id + (approve ? '/approve' : '/reject');
              this.pluto.post(url,{input:data}).subscribe(data => {
                toastr.success('Data Processed : '+data.status);
                jQuery('#reRender').click()
              })
            }
          })
    }

    public changeAmount(amount) {
      let ang = this;
      ang.input.amount = amount;
    }

    public changeAmountVendor(amount) {
      let ang = this;
      ang.input.amountVendor = amount;
    }

    private resetInput() {
      let ang = this;

      ang.input = {
        userId: '',
        providerId: '',
        senderBankId: '',
        senderBankAccountNumber: 0,
        receiverBankId: '',
        receiverBankAccountNumber: 0,
        amount: '',
        amountVendor: '',
      }
    }

    public manualDeposit(data) {
      let ang = this;
      var data = data.value;
      let url = '/shareproduct/accounting/manual/deposit';

      data.amount = parseFloat(data.amount);
      data.senderBankAccountNumber = data.senderBankAccountNumber ? data.senderBankAccountNumber.toString() : 0;
      data.receiverBankAccountNumber = data.receiverBankAccountNumber ? data.receiverBankAccountNumber.toString() : 0;
      data.status = 'approved';

      ang.pluto.post(url,{input:data}).subscribe(resp => {
        toastr.success('Data Processed : '+ resp.message);
        ang.reRender()
      })

      ang.resetInput();
    }

    public depositVendor(data) {
      let ang = this;
      var data = data.value;
      let url = '/shareproduct/accounting/vendor/deposit';

      data.amount = parseFloat(data.amountVendor);
      data.status = 'approved';
      data.depositDetailsType = 'depositVendor';

      ang.pluto.post(url,{input:data}).subscribe(resp => {
        toastr.success('Data Processed : '+ resp.message);
        ang.reRender()
      })

      ang.resetInput();
    }

    public topUpClient(data) {
      let ang = this;
      var data = data.value;
      console.log(data)
      let url = '/shareproduct/accounting/deposits';

      data.amount = parseFloat(data.amount);
      if (!!!data.amount) return false;

      ang.pluto.post(url,{input:data}).subscribe(resp => {
        toastr.success('New TopUp Processed');
        ang.reRender()
      })

      ang.resetInput();
    }

    private changeUrlDatatable() {
      let ang = this;
      let urlAdditional = '?filter=' + JSON.stringify(ang.filter);

      ang.dtInstance.ajax.url( ang.ENV.API_URL + ang.config.api + urlAdditional).load();
    }


    reRender(): void {
      jQuery(".dataTables-topup").dataTable().fnDraw();
      jQuery('.close').click().click()
    }

    closeForm(index){
      $('#edit').attr('href','#'+index);
    }

    clear() {
      var ang = this;

      ang.minDate = '';
      ang.maxDate = '';
      $('.date-picker').val('');
      $('#userAlias').val('');
      $('#status').val('')
    }

    changeDate(event) {
      let ang = this;

      const fromDate = ang.minDate;
      const toDate = ang.maxDate;
      const minDate = moment(fromDate).toISOString()
      const maxDate = moment(toDate).add(1, 'days').toISOString()

      if (moment(fromDate).isValid() && moment(toDate).isValid()) {
        ang.filter.where.createdAt = {between: [minDate,maxDate] };
        ang.changeUrlDatatable()
      }
    }

    ngOnInit():void {
      let ang = this;

      $('#userAlias').on( 'change', function () {
        ang.filter.where.userId = this.value;
        ang.changeUrlDatatable()
      });

      ang.dtOptions = ang.dataTablesCreator.config(ang.config);

    }

    ngAfterViewInit(){
      let ang = this;

      $('.date-picker').on( 'change', function () {
        const fromDate = ang.minDate;
        const toDate = ang.maxDate;
        const minDate = moment(fromDate).toISOString()
        const maxDate = moment(toDate).add(1, 'days').toISOString()

        if (moment(fromDate).isValid() && moment(toDate).isValid()) {
          ang.filter.where.createdAt = {between: [minDate,maxDate] };
          ang.changeUrlDatatable()
        }
      });

      $('#status').click();
    }

}
