import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';

import { dataTablesCreator } from '../../../services/datatables.service';
import { plutoRequestService } from '../../../services/plutoRequest.service';
import { environment } from '../../../../environments/environment';

declare var jQuery:any;
declare var moment:any;
declare var swal: any;
declare var toastr:any;

@Component({
    selector: 'scrapelogs',
    templateUrl: 'scrapelogs.template.html',
})
export class scrapelogsComponent implements OnInit, AfterViewInit {
    @ViewChild(DataTableDirective)
    public datatableElement: DataTableDirective;
    dtTrigger = new Subject();
    dataClicked=[];
    userJson = [];
    dtInstance;
    mode = 'all';
    scrapeModules;
    userAlias=[];
    detailScrapeModule=[];

    dtOptions;

    constructor(private dataTablesCreator : dataTablesCreator, private pluto : plutoRequestService){
      let ang = this;

      ang.pluto.get('/userApi',{}).subscribe(data => {
        ang.userJson = data.result;
        data.result.map(user => {
          ang.userAlias[user.group] = user.username;
        })
      })

      ang.pluto.get('/scrapemodules',{}).subscribe(data => {
        ang.scrapeModules = data;
      })

      // this.pluto.get('/1/flight/api/useraliases',{}).subscribe(data => {
      //   this.userAlias = data
      // })
    }

    tableCreator = [{
                      title: 'ID',
                      data: 'id',
                      orderable: true,
                      searchAble: true,
                      visible: false,
                      className: 'text-center',
                    },
                    {
                      title: 'Client',
                      data: 'userId',
                      orderable: false,
                      searchAble: true,
                      // render: (data) => data ? this.userJson[data] : ''
                      render: (data) => data ? `<h3 style="color:#05846b; text-transform:capitalize;">${this.userAlias[data]}</h3>` : '',
                    },
                    {
                      title: 'Maskapai',
                      data: 'scrapeModuleId',
                      orderable: false,
                      searchAble: true,
                      className: 'text-center',
                      render: (data) => `<h4 style="text-transform:capitalize;">${data}</h4>`,
                    },
                    {
                      title: 'Date',
                      data: 'createdAt',
                      render: (data) => {return `${moment(data).format('HH:mm:ss, MMMM/DD/YYYY')}`},
                    },
                    {
                      title: 'Action Name',
                      data: 'action',
                      searchAble: true,
                      className: 'text-center',
                      render: (data) => `<h4>${data}</h4>`,
                    },
                    {
                      title: 'Error Message',
                      data: 'errorMessage',
                      render: (data) => data.split('\n').slice(0, 2).join('<br />').split('%').slice(0,2).join('%<br/>'),
                      searchAble: true
                    },
                    {
                      title: 'Booking Code',
                      data: 'query.booking_code',
                      render: (data) => data ? `<h3 style="color:#05846b;">${data}</h3>` : '',
                      className: 'text-center',
                      searchAble: true
                    },
                    {
                      title: 'Processed',
                      data: 'processed',
                      render: (data) => data ? '<span class="label label-success">Yes</span>' : '<button class="btn btn-sm btn-outline btn-primary" (click)="process()" type="button">Process</button>',
                      className: 'text-center',
                      searchAble: true,
                    },
                    {
                      title: 'userID',
                      data: 'userId',
                      searchAble: true,
                      visible: false
                    },
                    {
                      title: 'Details',
                      data: null,
                      defaultContent: '<button type="button" class="btn btn-primary btn-circle" title="View details"><i class="fa fa-unsorted"></i></button>',
                      orderable: false,
                      className: 'text-center'
                    },
                  ];
    config = {
                api : '/scrapeLogs',
                // clickHandler : 'self.someClickHandler(data)',
                table : this.tableCreator,
                dataClicked : this.dataClicked,
                smButton : this.clickProcess,
                editForm: this.editForm,
                dataTablesCreator : this.dataTablesCreator,
                pluto : this.pluto,
                primaryKey : 'id'
             }

    getInstance(datatableElement: DataTableDirective): void {
    datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
      {
        // this.table = dtInstance;
        const table = dtInstance;
        this.dtInstance = table;

        //  Search By
         const orderId = table.columns(0);
         $('#order_id').on( 'keyup', function () {
           orderId.search( this.value ).draw();
         });

         const scrape = table.columns(2);
         $('#scrape').on( 'change', function () {
           scrape.search( this.value ).draw();
         });

         const action = table.columns(4);
         $('#action').on( 'keyup', function () {
           action.search( this.value ).draw();
         });

         const date = table.columns(3);
         $('#date_modified').on( 'keyup', function () {
           date.search( this.value ).draw();
         });

         const error = table.columns(5);
         $('#error').on( 'keyup', function () {
           error.search( '%' + this.value + '%', true ).draw();
         });

         const userId = table.columns(8);
         $('#userAlias').on( 'change', function () {
           userId.search( this.value ).draw();
         });

        var detailRows = [];
        const $table = $('.dataTables-example');
        let format = {
                        'title':{
                          'Account Id':'accountId',
                          'Start Time':'startedAt',
                          'End Time':'updatedAt',
                          'Scrape Id':'scrapeModuleId'
                        },
                        'tab':{
                          'Query':'query  ? JSON.stringify(data.query,null,4) : "" ',
                          'Scraper':'scraper  ? JSON.stringify(data.scraper,null,4) : "" ',
                          'Cookies':'cookies  ? JSON.stringify(data.cookies,null,4) : "" ',
                          'Capture':'capture  ? JSON.stringify(data.capture,null,4) : "" '
                        },
                        'id':'id'
                     }
        this.dataTablesCreator.childCreator({ table  : table,
                                              $table : $table,
                                              button : '.btn-circle',
                                              format : format
                                            })
      });

    }

    toggleAll(){
      this.dtInstance.columns(7).search('').draw();
      this.mode = 'all';
    }

    toggleBox(){
      this.dtInstance.columns(7).search('false').draw();
      this.mode = 'box';
    }

    toggleSpam(){
      this.dtInstance.columns(7).search('true').draw();
      this.mode = 'spam';
    }

    changeDetail(event,index){
      jQuery('.setting-mas').find('label').css('color','')
      jQuery(event.target).css('color','black')
      this.detailScrapeModule[0] = this.scrapeModules[index];
    }

    toggleSetting(name,status){
      this.pluto.put('/scrapemodules/toggle/'+name,{input:{'active':!status}}).subscribe(data => {
        this.pluto.get('/scrapemodules',{}).subscribe(data => {
          this.scrapeModules = data;
        })
      })

      // Reload scrape
    }

    clickProcess(data,e){
      var that = this;
      swal({
        title: 'Option Process',
        input: 'select',
        inputOptions: {
          'One': 'One Error',
          'All': 'All Error',
        },
        inputPlaceholder: 'Select process',
        showCancelButton: true,
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value === 'One') {
              that.editForm({value:{"processed":true}},0,data.id)
              resolve()
            } else if (value === 'All') {
              that.editForm({"processed":true},1,data.errorMessage.slice(0, 100))
              resolve()
            } else {
              reject('You need to select option process:)')
            }
          })
        }
        }).then(function (result) {
        swal({
          type: 'success',
          html: result + ' Error is Processed'
        })
      }).catch(swal.noop);
    }

    editForm(data,index,id){
      if(index == 1){
        let url = '/flight/api/ScrapeLogs/update?where=';
        url += encodeURIComponent('{"errorMessage":{"like":"%'+id.split('\n').slice(1, 2)+'%"}}');
        this.pluto.post(url,{input:data}).subscribe(data => {
          toastr.success('Data Processed : '+data.count);
        })
      } else {
        this.dataTablesCreator.editForm(null,data,index,id);
      }
      jQuery('#reRender').click()
    }

    reRender(): void {
      jQuery(".dataTables-example").dataTable().fnDraw();
    }

    settingMaskapai(){

    }

    closeForm(index){
      $('#edit').attr('href','#'+index);
    }

      ngOnInit():void {

        this.dtOptions = this.dataTablesCreator.config(this.config);

        jQuery('#date_added,#date_modified').datepicker();
      }

      ngAfterViewInit(){
        $('.instance').click();
      }

}
