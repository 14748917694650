import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import {NavigationComponent} from "./navigation.component";
import { AuthGuard} from "../../../_guard/auth.guard";
import { pipeModule } from '../../../components/filter/pipe.module';
import { sessionService } from '../../../services/session.service';

@NgModule({
    declarations: [NavigationComponent],
    imports     : [BrowserModule, RouterModule, pipeModule],
    exports     : [NavigationComponent],
    providers   : [AuthGuard, sessionService],
})

export class NavigationModule {}
