import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { pipeModule } from '../../../components/filter/pipe.module';
import { dataTablesCreator } from '../../../services/datatables.service';

import {reportComponent} from "./report.component";

import {reportBookingComponent} from "./report-booking/report-booking.component";
import {reportIssuedComponent} from "./report-issued/report-issued.component";

@NgModule({
    declarations: [reportComponent,reportBookingComponent,reportIssuedComponent],
    imports     : [BrowserModule, RouterModule, pipeModule, DataTablesModule,ReactiveFormsModule, FormsModule],
    providers   : [dataTablesCreator],
    exports     : [reportComponent, reportBookingComponent,reportIssuedComponent],
})

export class reportModule {}
