import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
// import { Chart } from 'chart.js';
import { Chart, ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DatepickerOptions } from 'ng2-datepicker';

import { plutoRequestService } from '../../../services/plutoRequest.service';
import { environment } from '../../../../environments/environment';

declare var _:any;
declare var jQuery:any;
declare var moment:any;
declare var swal: any;
declare var toastr:any;

@Component({
    selector: 'log-share-product',
    templateUrl: 'log-share-product.template.html',
})
export class statisticLogSPComponent implements OnInit, AfterViewInit {
    public barChartOptions: ChartOptions = {
      responsive: true,
      // We use these empty structures as placeholders for dynamic theming.
      scales: { xAxes: [{}], yAxes: [{}] },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      }
    };
    public barProviderLabels = [];
    public barProviderType: ChartType = 'bar';
    public barProviderLegend = true;
    public barProviderPlugins = [pluginDataLabels];
    public barProviderData: ChartDataSets[] = [
        {data:[], label:'Rejected'},
        {data:[], label:'Approved'},
      // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
      // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    ];

    public barClientLabels = [];
    public barClientType: ChartType = 'bar';
    public barClientLegend = true;
    public barClientPlugins = [pluginDataLabels];
    public barClientData: ChartDataSets[] = [
        {data:[], label:'Rejected'},
        {data:[], label:'Approved'},
    ];
    userJson;
    providers = [];
    client = [];
    userId = '';
    fromDate = moment();
    toDate = moment();
    options: DatepickerOptions = {
        // minYear: 1970,
        // maxYear: 2030,
        displayFormat: 'DD MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        // minDate: new Date(Date.now()), // Minimal selectable date
        // maxDate: new Date(Date.now()),  // Maximal selectable date
        addClass: 'date-picker', // Optional, value to pass on to [ngClass] on the input field
        addStyle: {'text-align':'center', 'width':'280px'}, // Optional, value to pass to [ngStyle] on the input field
        fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    };

    constructor(private pluto : plutoRequestService){
      let ang = this;

      ang.pluto.get('/userApi',{}).subscribe(users => {
        ang.userJson = users.result
        users.result.map(user => {
          ang.client[user.group] = {};
          ang.client[user.group]['label'] = user.username;
          ang.client[user.group]['activity'] = 0;
          ang.client[user.group]['approved'] = 0;
          ang.client[user.group]['rejected'] = 0;
        })
      })


    }

    ngOnInit():void {
      var ang = this;

      ang.getStatistic();
    }

    getStatistic(update = false) {
      var ang = this;
      const formatFromDate = moment(ang.fromDate).format('YYYY-MM-DD');
      const formatToDate = moment(ang.toDate).format('YYYY-MM-DD');
      const minDate = moment(formatFromDate).toISOString();
      const maxDate = moment(formatToDate).add(24, 'hours').toISOString();
      var queryDate = 'userId='+ang.userId+'&date_from=' + minDate + '&date_to=' + maxDate;
      // ang.logs = [];
      // ang.client = [];

      ang.pluto.get('/shareproduct/accounting/vendors',{}).subscribe(vendors => {
        vendors.map(vendor => {
          ang.providers[vendor.providerId] = {};
          ang.providers[vendor.providerId]['label'] = vendor.providerId;
          ang.providers[vendor.providerId]['activity'] = 0;
          ang.providers[vendor.providerId]['approved'] = 0;
          ang.providers[vendor.providerId]['rejected'] = 0;
        })

        ang.pluto.get('/shareproduct/accounting/salesdate?' + queryDate,{}).subscribe(sales => {
          sales.map(sale => {
            ang.providers[sale.providerId][sale.status]++;
            ang.client[sale.userId][sale.status]++;
            ang.providers[sale.providerId].activity++;
            ang.client[sale.userId].activity++;
          })

          ang.barProviderLabels = _.keys(ang.providers);
          ang.barProviderData[0].data = _.values(ang.providers).map(data => data.rejected);
          ang.barProviderData[1].data = _.values(ang.providers).map(data => data.approved);

          ang.barClientLabels = _.filter(_.values(ang.client), client => client && client.activity > 0).map(data => data.label);
          ang.barClientData[0].data = _.filter(_.values(ang.client), client => client && client.activity > 0).map(data => data.rejected);
          ang.barClientData[1].data = _.filter(_.values(ang.client), client => client && client.activity > 0).map(data => data.approved);
        })
      })

        // ang.pluto.get('/bookFlightBetween?' + queryDate,{}).subscribe(reports => {
        //   reports.map(report => {
        //     report.log_airline.map(log => {
        //       ang.airlines[report.airline].activity++;
        //       ang.airlines[report.airline][log.activity]++;
        //
        //       ang.client[report.userId].activity++;
        //       ang.client[report.userId][log.activity]++;
        //     })
        //
        //   })
        //
        //     ang.barAirlineLabels = _.filter(_.values(ang.airlines), airlines => airlines.activity > 0).map(data => data.label);
        //     ang.barAirlineData[0].data = _.filter(_.values(ang.airlines), airlines => airlines.activity > 0).map(data => data.booking);
        //     ang.barAirlineData[1].data = _.filter(_.values(ang.airlines), airlines => airlines.activity > 0).map(data => data.issued);
        //
        //     ang.barClientLabels = _.filter(_.values(ang.client), client => client && client.activity > 0).map(data => data.label);
        //     ang.barClientData[0].data = _.filter(_.values(ang.client), client => client && client.activity > 0).map(data => data.booking);
        //     ang.barClientData[1].data = _.filter(_.values(ang.client), client => client && client.activity > 0).map(data => data.issued);
        //
        // })
    }

    private randomColorGenerator() {
      return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
    };

    ngAfterViewInit(){
      let ang = this;

    }

}
