import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';

import { dataTablesCreator } from '../../../services/datatables.service';
import { plutoRequestService } from '../../../services/plutoRequest.service';
import { environment } from '../../../../environments/environment';

declare var jQuery:any;
declare var moment:any;
declare var swal: any;
declare var toastr:any;

// const BASE_URL = 'https://api-dev.airbinder.co.id';

@Component({
    selector: 'flight',
    templateUrl: 'flight.template.html',
})
export class flightShareComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  public datatableElement: DataTableDirective;
  private ENV = environment;
  dtTrigger = new Subject();
  idClient;
  userJson;
  dtInstance;
  userAlias = []
  providers;
  fromDate = moment().format('MM/DD/YYYY');
  toDate = moment().format('MM/DD/YYYY');
  accountVendor=[];
  filter = {
    where: {
      userId: undefined,
      createdAt: undefined,
      accountId: undefined,
      productName: 'flight',
      description: undefined,
      providerId: undefined,
    }
  }
  dtOptions;
  options: DatepickerOptions = {
      // minYear: 1970,
      // maxYear: 2030,
      displayFormat: 'DD MMMM YYYY',
      barTitleFormat: 'MMMM YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
      // minDate: new Date(Date.now()), // Minimal selectable date
      // maxDate: new Date(Date.now()),  // Maximal selectable date
      addClass: 'date-picker', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {'text-align':'center', 'width':'280px'}, // Optional, value to pass to [ngStyle] on the input field
      fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
  };

  constructor(private dataTablesCreator : dataTablesCreator, private pluto : plutoRequestService){
    let ang = this;

    ang.pluto.get('/userApi',{}).subscribe(users => {
      ang.userJson = users.result
      users.result.map(user => {
        ang.userAlias[user.group] = user.username;
      })
    })

    ang.pluto.get('/shareproduct/providers',{}).subscribe(providers => {
      ang.providers = providers;
    })

    ang.idClient = ang.pluto.getIdClient();
  }

  tableCreator = [{
                    title: 'ID',
                    data: 'id',
                    orderable: true,
                    // searchAble: true,
                    visible: false,
                    className: 'text-center',
                  },
                  {
                    title: 'Product Detail',
                    data: 'description',
                    className: 'text-center all',
                    orderable: false,
                    searchAble: true,
                    render: (data) => data ? `<h3>${data.split('-')[0]}</h3>${data.split('-')[1]}` : '' ,
                  },
                  {
                    title: 'Amount',
                    data: 'amount',
                    orderable: true,
                    render: (data) => data ? 'Rp '+ `${data.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}` : '',
                    searchAble: true,
                  },
                  {
                    title: 'Updated at',
                    className: 'text-center',
                    data: 'updatedAt',
                    render: (data) => {return `${moment(data).format('DD MMMM YYYY HH:mm')}`},
                  },
                  {
                    title: 'Client',
                    data: 'userId',
                    className: 'text-center',
                    searchAble: true,
                    render: (data) => data ? `<b style="text-transform:capitalize;"> ${this.userAlias[data]} </b>` : ''
                  },
                  {
                    title: 'Status',
                    data: 'status',
                    render: (data) => data == 'unapproved' ? '<h3 style="color:yellow">Pending</h3>' : (data == 'approved' ? '<h3 style="color:green">Approved</h3>' : (data == 'rejected') ? '<h3 style="color:grey">Rejected</h3>' : '<h3 style="color:red">Error</h3>'),
                    className: 'text-center',
                    searchAble: true
                  },
                  {
                    title: 'Action',
                    data: 'status',
                    render: (data) => data == 'unapproved' ? '<button class="btn btn-sm btn-info btn-circle" title="approve" (click)="setApprove()" type="button"><i class="fa fa-check"></i></button> <button class="btn btn-sm btn-warning btn-circle" title="reject" (click)="setError()" type="button"><i class="fa fa-times"></i></button>' : '',
                    orderable: false,
                    className: 'text-center all',
                  },
                ];
  config = {
              api : '/shareproduct/accounting/sales',
              table : this.tableCreator,
              smButton : this.clickProcess,
              dataTablesCreator : this.dataTablesCreator,
              pluto : this.pluto,
           }

  getInstance(datatableElement: DataTableDirective): void {
  datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
    {
      let ang = this;
      const table = dtInstance;

      ang.dtInstance = table;

      ang.pluto.get('/shareproduct/accounting/vendors',{}).subscribe(vendors => {
        ang.accountVendor=[];
        vendors.map(vendor => {
          ang.accountVendor.push(vendor.accountId);
        })
        ang.filter.where.accountId ={nin: ang.accountVendor};
        ang.changeUrlDatatable();
      })

      const status = table.columns(5);
      $('#status').on( 'change', function () {
         status.search( this.value ).draw();
      });

      const action = table.column(6);
      if(ang.idClient) {
        // client.search( ang.idClient ).draw();
        action.visible(false)
      }

      ang.changeDate();
    });

  }

  clickProcess(data,e){
    let that = this;
    let approve = $(e).hasClass('btn-info') || $(e).hasClass('fa-check');
    let reject = $(e).hasClass('btn-warning') || $(e).hasClass('fa-times');

    swal({
          title: 'Are you sure to ' + (approve ? 'approve ?' : 'reject ?'),
          text: '',
          type: approve ? 'info' : 'warning',
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          // closeOnConfirm: true,
          // closeOnCancel: true
        }).then((result) => {
          if (result) {
            let url = '/shareproduct/accounting/sales/' + data.id + (approve ? '/approve' : '/reject');
            this.pluto.post(url,{input:data}).subscribe(data => {
              toastr.success('Data Processed : '+ data.status);
              jQuery('#reRender').click()
            })
          }
        })
  }

  private changeUrlDatatable() {
    let ang = this;
    let urlAdditional = '?filter=' + JSON.stringify(ang.filter);

    ang.dtInstance.ajax.url( ang.ENV.API_URL + ang.config.api + urlAdditional).load();
  }


  reRender(): void {
    let ang = this;

    // ang.dateNow = $('#from_date').val();
    jQuery(".dataTables-topup").dataTable().fnDraw();
  }

  closeForm(index){
    $('#edit').attr('href','#'+index);
  }

  changePoduct(val) {
    let ang = this;

    ang.filter.where.description = val ? {regexp: val } : undefined;
    ang.changeUrlDatatable();
  }

  changeProvider(val) {
    let ang = this;

    ang.filter.where.providerId = val ? {regexp: val } : undefined;
    ang.changeUrlDatatable();
  }

  changeDate() {
    let ang = this;

    const formatFromDate = moment(ang.fromDate).format('YYYY-MM-DD');
    const formatToDate = moment(ang.toDate).format('YYYY-MM-DD');
    const minDate = moment(formatFromDate).toISOString();
    const maxDate = moment(formatToDate).add(24, 'hours').toISOString();

    ang.filter.where.createdAt = ang.fromDate && ang.toDate ? {between: [minDate,maxDate] } : undefined;
    ang.changeUrlDatatable();
  }

  clear() {
    var ang = this;

    ang.fromDate = '';
    ang.toDate = '';
    $('.date-picker').val('');
    $('#userAlias').val('').change();
    $('#status').val('').change();
    $('#product-detail').val('');
    $('#provider').val('');

    ang.changePoduct(false);
    ang.changeDate();
  }

  ngOnInit():void {
    let ang = this;

    // $('#from_date').on( 'change', function () {
    //   ang.fromDate = this.value;
    //   ang.changeDate()
    // });

    $('#userAlias').on( 'change', function () {
      ang.filter.where.userId = this.value ? this.value : undefined;
      ang.changeUrlDatatable();
    });

    ang.dtOptions = ang.dataTablesCreator.config(ang.config);
  }

  ngAfterViewInit(){
    $('.instance').click();
  }

}
