import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
// import { HttpClient } from '@angular/common/http';

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;
  channel: any;

  constructor() {
    Pusher.logToConsole = true;
    this.pusher = new Pusher(environment.PUSHER.KEY, {
      cluster: environment.PUSHER.CLUSTER,
      forceTLS: true
    });
    this.channel = this.pusher.subscribe('standart-channel');
  }

}
