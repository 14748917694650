import { Component,OnInit,AfterViewInit,ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { dataTablesCreator } from '../../../services/datatables.service';
// import { searchPipe } from '../../components/filter/search.component';

declare var jQuery:any;
declare var swal: any;
declare var toastr:any;

const urlUpload = environment.URL_UPLOAD;

@Component({
    selector: 'hotels-scrape',
    templateUrl: 'hotels-scrape.template.html',
    styleUrls: ['hotels-scrape.css']
})
export class hotelsScrapeComponent implements OnInit, AfterViewInit {
  @ViewChild('nameEdit') name: ElementRef;
  @ViewChild('image') image: ElementRef;
  @ViewChild(DataTableDirective)
  public datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  dataClicked=[];
  dtOptions;
  hotelId;
  airline;
  search = {};
  nameFile=[];
  filePreviewPath=[];

  constructor(private dataTablesCreator: dataTablesCreator, private sanitizer: DomSanitizer, private ElementRef:ElementRef){}

  public uploader:FileUploader = new FileUploader({url: urlUpload, itemAlias:'error'});
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }

  nameFolder(nama) {
    this.uploader.options.itemAlias = nama;
  }

  nameFolderEdit(){
    this.uploader.options.itemAlias = this.name.nativeElement.value;
  }

  removeImage(el){
    document.getElementById(el).remove()
  }

  tableCreator = [{
                    title: 'Hotel Name',
                    data: 'name',
                    searchAble: true,
                    orderable: true,
                    render: (data) => `<h3 style="color:#05846b">${data}</h3>`,
                    // className: 'increment'
                  },
                  {
                    title: 'Region',
                    data: 'region',
                    // orderable: true,
                    // searchAble: true
                  },
                  {
                    title: 'Continent',
                    data: 'continent',
                    // searchAble: true,
                  },
                  {
                    title: 'ID Scrape',
                    data: 'hotelCodeProviderId',
                    searchAble: true,
                    render: (data) => `<b>${data}</b>`,
                  },
                  {
                    title: 'Connect',
                    data: 'hotelCodeProvider.hotelId',
                    render: (data) => {return data ? '<h4 style="color:green"><i class="fa fa-check"></i></h4>' : '<h4 style="color:red"><i class="fa fa-remove"></i></h4>'},
                    orderable: true,
                    className: 'text-center'
                  },
                  {
                    title: 'Details',
                    data: null,
                    defaultContent: '<button type="button" class="btn btn-circle btn-info" title="View details"><i class="fa fa-unsorted"></i></button>',
                    orderable: false,
                    className: 'text-center'
                  },
                ];
  config = {
              api : '/hotel/scrape',
              clickHandler : this.someClickHandler,
              table : this.tableCreator,
              dataClicked : this.dataClicked,
              primaryKey : 'id'
           }

 getInstance(datatableElement: DataTableDirective): void {
 datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
   {
     // this.table = dtInstance;
     const table = dtInstance;

    //  Search By
     const airline = table.columns(0);
     $('#hotel').on( 'keyup', function () {
       airline.search( this.value ).draw();
     });

     const label = table.columns(1);
     $('#region').on( 'keyup', function () {
       label.search( this.value ).draw();
     });

     const scrape = table.columns(3);
     $('#scrape').on( 'keyup', function () {
       scrape.search( this.value ).draw();
     });

     const icao = table.columns(4);
     $('#icao').on( 'keyup', function () {
       icao.search( this.value ).draw();
     });

     var detailRows = [];
     const $table = $('.dataTables-example');
     let format = {
                     'title':{
                       'Address':'address',
                       'Location':'location',
                       'Rating':'rating',
                       'Website':'website'
                     },
                     'tab':{
                       'Phone':'phone',
                       'facsimile':'facsimile',
                     },
                     'id':'hotelCodeProviderId'
                  }
     this.dataTablesCreator.childCreator({ table  : table,
                                           $table : $table,
                                           button : '.btn-info',
                                           format : format
                                         })
   });

  }

  someClickHandler(e,info: any,primaryKey): void {
    let checkIsset = false;
    if (typeof this.dataClicked.length !== 'undefined'){
      checkIsset = this.dataClicked.find( function( data ) {
        return data[primaryKey] === info[primaryKey];
      });
    }

    if (checkIsset) {
      this.dataClicked.forEach(function(value,index,obj){
            if (value[primaryKey] == info[primaryKey]){
              obj.splice(index, 1);
            }
      })
      $(e.target).parents('tr').removeClass('clicked').css('background-color','white');
    } else {
      // if (info.hotelId == null && typeof info.newLocation == 'undefined')
      info.newLocation = info.hotelDetails.address + ', ' + info.hotelDetails.location + ', ' + info.hotelDetails.continent + ', ' + info.hotelDetails.region;
      this.dataClicked.push(info);
      $(e.target).parents('tr').addClass('clicked').css('background-color','#1ab3944d');
    }

  }

  addHotel(data){
    let dataSend = data.value;
    if (Object.keys(dataSend).find(data => dataSend[data] === null)) {
      toastr.error('Please check empty form');
      return false;
    }
    dataSend.location = dataSend.location.split(",");
    dataSend.image = this.nameFile;
    dataSend.customUrl = '/hotel';
    dataSend.callBack = 'id';
    dataSend.catchData = 'map'
    this.dataTablesCreator.add(dataSend).subscribe(
       suc => {
         $('.close').click().click()
         $('#connectProvider').click();
         this.hotelId = suc;
        //  $('input[name="hotelId"]').val(suc)
         $('input[name="providerId"]').focus()
         jQuery(".dataTables-example").dataTable().fnDraw();
       }
    );
    this.nameFile = [];
    this.uploader = new FileUploader({url: urlUpload, itemAlias:'error'});
    jQuery(".dataTables-example").dataTable().fnDraw();
  }

  connectProvider(data){
    let dataSend = data.value;
    console.log(dataSend)
    dataSend.method = 'post';
    // dataSend.customUrl = '/1/hotel/api/hotelcodeproviders/' + dataSend.providerId + '-' + dataSend.code;
    dataSend.customUrl = '/hotel/connect';
    this.dataTablesCreator.add(dataSend)
  }


  editForm(data,index,id){
    if (data.value.hotelId == null){
      let image = this.image.nativeElement.getElementsByTagName('img');
      Object.keys(image).forEach(key => this.nameFile.push(image[key].getAttribute('src')));
      data.value.image = this.nameFile;
        this.addHotel(data);
    } else {
      toastr.warning('Data not Saved. Please Edit Data Hotel in Menu "Data Hotel"');
    }

    // Hiden Modal Manual
    let next = index+1;
    jQuery('#'+index).modal('hide');
    $('.modal-backdrop').remove('div');
    if($('#' + next).length == 0) {
      $('#edit').attr('href','#0');
    } else {
      $('#edit').attr('href','#'+next).click();
    }

    // Delete dataClicked
    $('td:contains('+id+')').val(id).parents('tr').removeClass('clicked').css('background-color','white');
    this.dataClicked.forEach(function(value,index,obj){
      if (value.id == id){
        obj.splice(index, 1);
      }
    })
    jQuery(".dataTables-example").dataTable().fnDraw();
  }

  deleteForm(){
    let dataTablesCreator = this.dataTablesCreator;
    dataTablesCreator.delete(this.dataClicked);
    this.dataClicked.forEach(function(value,index,obj){
      $('td:contains('+value.id+')').val(value.id).parents('tr').removeClass('clicked').css('background-color','white');
    })
  }

  closeForm(index){
    $('#edit').attr('href','#'+index);
  }

  ngOnInit(){
    this.dtOptions = this.dataTablesCreator.config(this.config);
    let that = this;
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      var responsePath = JSON.parse(response);
      if (!responsePath.status){
        toastr.error(responsePath.msg);
      } else {
        toastr.success(responsePath.msg);
        that.nameFile.push(urlUpload+responsePath.generatedName);
      }
    };
    this.uploader.onAfterAddingFile = (fileItem) => {

      that.filePreviewPath[fileItem._file.name] = that.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
    }
  }

  ngAfterViewInit(){
    $('.instance').click();
  }


}
