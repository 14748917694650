// import { Component } from '@angular/core';
import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { accountService } from '../../../../services/account.service';
import { airlinesService } from '../../../../services/airlines.service';
import { plutoRequestService } from '../../../../services/plutoRequest.service';
import { searchPipe } from '../../../../components/filter/search.component';

declare var $ :any;
declare var _ :any;
declare var jQuery:any;
declare var toastr:any;
declare var moment:any;
declare var modal:any;
declare var Pace:any;

const ID_SHARE = 71;

@Component({
    selector: 'manage_account_product',
    templateUrl: 'manage-account-product.template.html'
})
export class manageAccountProductComponent {
  public accounts: any[] = [];
  public airlines: any[];
  public requirements: any[];
  public requirement: any;
  public sources: any;
  public product;
  public userApi: any;
  public scrapeModules: any;
  public idClient: any;
  public inputAccount = [];
  public inputEditAccount = {product: '', account_id:'', userId:''};
  public filter = {client: 71, airline:'', username:'', status:''};

  constructor(private _accountService: accountService, private _airlinesService: airlinesService, private pluto: plutoRequestService) {

  }

  extractSource(requirement: any) {
      let sources = [];

      Object.keys(requirement.source).map((key,iKey) => {
          sources.push({
              prop: key,
              rule: requirement.source[key],
          })
      })

      this.inputAccount.push({version: 1});
      this.sources = sources;
      this.requirement = requirement;
  }

  generateInputTypeForSource(rule: any): string {
      let trans = {
          'string': 'text',
          'number': 'number',
      };

      return trans[rule.type];
  }

  ngOnInit(){
    this.getDataProduct();

    this._accountService.getAirlineRequirements(1).subscribe(data => {
        var dataRequirement = [];
        Object.keys(data).map((airline,iAirline) => {
          var result = [];
          var source = [];

          Object.keys(data[airline].properties).map((property, iProperty) => {
            source[property] = {
              'type': property['type'],
              'required': data[airline].required.includes(property)*1,
              'label': property['description']
            }
          })

          result['name'] = airline;
          result['label'] = airline;
          result['source'] = source;

          dataRequirement.push(result);
        })

        this.requirements = dataRequirement;
        this.requirements.push(this._accountService.getHotelRequirements());
        this.requirements.push(this._accountService.getPPOBRequirements());
    });

    this.pluto.get('/userApi',{}).subscribe(data => {
      this.userApi = data.result;
    })

    this.pluto.get('/scrapemodules?filter[where][active]=1',{}).subscribe(data => {
      this.scrapeModules = data;
    })

    this.idClient = this.pluto.getIdClient();

  }

  getDataProduct() {
    let accounts = []
    this._accountService.getAirlineV1().subscribe(data => {
      data.map(accountV1 => {
        let account = {
          owner: accountV1.userId,
          airline: accountV1.scrapeModuleId,
          active: accountV1.active,
          id: accountV1.id,
          username: accountV1.uniqueId,
          source: {
            username: accountV1.uniqueId,
          },
          createdAt: moment(accountV1.createdAt).format('DD MMMM YYYY'),
          updatedAt: moment(accountV1.updatedAt).format('DD MMMM YYYY'),
          locked: accountV1.locked,
          errorMessage: accountV1.errorMessage,
          product: 'flight',
          version: 1,
        };

        accounts.push(account)
      })
    });
    this._accountService.getHotel().subscribe(data => {
      data.map(hotel => {
        let account = {
          owner: hotel.userId,
          airline: 'hotel',
          active: hotel.active,
          id: hotel.id,
          username: hotel.uniqueId,
          source: {
            username: hotel.uniqueId,
          },
          createdAt: moment(hotel.createdAt).format('DD MMMM YYYY'),
          updatedAt: moment(hotel.updatedAt).format('DD MMMM YYYY'),
          locked: hotel.locked,
          errorMessage: hotel.errorMessage,
          product: 'hotel',
          version: 1,
        }

        accounts.push(account)
      })
    });
    this._accountService.getPPOB().subscribe(data => {
      data.map(ppob => {
        let account = {
          owner: ppob.userId,
          airline: 'ppob',
          active: ppob.active,
          id: ppob.id,
          username: ppob.uniqueId,
          source: {
            username: ppob.uniqueId,
          },
          createdAt: moment(ppob.updatedAt).format('DD MMMM YYYY'),
          updatedAt: moment(ppob.updatedAt).format('DD MMMM YYYY'),
          locked: ppob.locked,
          errorMessage: ppob.errorMessage,
          product: 'ppob',
          version: 1,
        }

        accounts.push(account)
      })
      this.accounts = accounts;
    });

  }

  getClientName(users, id) {
    var user = users ? users.find(user => user.group === id) : false;
    return user ? user.username: '';
  }

  addAccount(dataForm){
    jQuery('.close').click().click();
    Pace.restart()
    let url = '/accounts';
    let dataPost = {};
    let data = dataForm.value;
    let airlineName = this.product.name;
    data.userId = 71;
    dataPost = Object.assign({'airline': airlineName}, data);

    let opt = {use_api_version: false, input: dataPost};

    this.pluto.post(url, opt).subscribe(response => {
      Pace.stop()
      if (response.errorMessage || response.error) {
        toastr.error(response.message);
        jQuery('#new-account').modal('show');
      } else {
        this.inputAccount = [];
        dataForm.reset();
        this.ngOnInit();
        toastr.success('Add Account Success');
      }
    })

  }

  deleteAccount(account) {
    let opt = {use_api_version: false};
    let product = account.product;
    let id = account.id;
    let url = '/accounts/' + product + '/' + id;

    this.pluto.delete(url, opt).subscribe(response => {
      if (response.count) {
        toastr.success('delete success');
        var ind
        this.accounts.splice(this.accounts.indexOf(account),1);
      } else {
        toastr.error('delete failed');
      }
    })

  }

  toggleStatus(data) {
    Pace.restart()
    let url = '/accounts/' + data.product + '/' + data.id;

          let postData = {active: !data.active}
          let opt = {use_api_version: false, input: postData}

          this.pluto.put(url, opt).subscribe(response => {
            Pace.stop()
            if (response.errorMessage) {
              toastr.error(response.errorMessage)
            } else {
              toastr.success('Toggle Status Success')
              this.accounts[this.accounts.indexOf(data)].active = !data.active;
            }
          })

  }

  showEditAccount(data) {
      // this.inputEditAccount = {
      this.inputEditAccount.product = data.airline,
      this.inputEditAccount.account_id = data.id,
      this.inputEditAccount.userId = data.owner
      // }

      this.extractSource(_.find(this.requirements, {'name': data.airline}))
      Object.keys(data.source).map((key,iKey) => {
          this.inputEditAccount[key] = data.source[key];
      })

      $('#edit-account').modal('show');
  }

  editAccount(data) {
    jQuery('.close').click().click();
    Pace.restart()
    let url = '/accounts/account/update';
    let postData = Object.assign(this.inputEditAccount, {edit: data.value});
    let opt = {use_api_version: false, input: postData}

    this.pluto.post(url, opt).subscribe(response => {
      Pace.stop();
      this.getDataProduct();
      toastr[response.status](response.message)
    })
  }
}
