import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { dataTablesCreator } from '../../../services/datatables.service';
import { plutoRequestService } from '../../../services/plutoRequest.service';

declare var jQuery:any;
declare var toastr:any;

@Component({
    selector: 'tracks',
    templateUrl: 'tracks.template.html'
})
export class tracksComponent implements OnInit, AfterViewInit{
  @ViewChild(DataTableDirective)
  public datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  dataClicked=[];
  scrapeModules;
  dtOptions;
  inputRoute = {
    airlineId: undefined,
    scrapeModuleId: undefined,
    destination: undefined,
    origin: undefined,
    trip: undefined,
    active: undefined,
    international: undefined,
    popular: undefined,
  }
  inputCross = {
    scrapeModuleId: undefined,
    airport: undefined,
  }

  constructor(private dataTablesCreator: dataTablesCreator, private pluto: plutoRequestService){

  }

  tableCreator = [
                  {
                    title: 'Scrape Module',
                    data: 'scrapeModuleId',
                    render: (data) => `<h1 style="color:#05846b; text-transform: capitalize;">${data}</h1>`,
                    searchAble: true,
                    orderable: false,
                    className: 'text-center'
                  },
                  {
                    title: 'Airline',
                    data: 'airlineId',
                    render: (data) => {return `<img class="img-thumbnail" src="https://cdn.airbinder.co.id/airlines/${data}.gif" alt="${data}" title="${data}" width="77px">`},
                    searchAble: true,
                    className: 'text-center',
                    visible: false,
                  },
                  {
                    title: 'Origin',
                    data: 'origin',
                    searchAble: true,
                    orderable: false,
                    render: (data) => `<h3>${data}</h3>`,
                    className: 'text-center',
                  },
                  {
                    title: 'Destination',
                    data: 'destination',
                    orderable: false,
                    render: (data) => `<h3>${data}</h3>`,
                    className: 'text-center',
                  },
                  {
                    title: 'ID',
                    data: 'id',
                    orderable: true,
                    visible: false,
                    render: (data) => `<h2>${data}</h2>`,
                    className: 'text-center',
                  },
                  {
                    title: 'Active',
                    data: 'active',
                    render: (data) => data ? '<h4 style="color:#05846b">Yes</h4>' : '<h4 style="color:red">No</h4>',
                    className: 'text-center'
                  },
                  // {
                  //   title: 'Details',
                  //   data: null,
                  //   defaultContent: '<button type="button" class="btn btn-primary btn-circle" title="View details"><i class="fa fa-unsorted"></i></button>',
                  //   orderable: false,
                  //   className: 'text-center'
                  // },
                ];
  config = {
              api : '/routes',
              table : this.tableCreator,
              // dataClicked : this.dataClicked,
              primaryKey : 'id'
           }

 getInstance(datatableElement: DataTableDirective): void {
 datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
   {
     // this.table = dtInstance;
     const table = dtInstance;

    //  Search By
     const id = table.columns(4);
     $('#id').on( 'keyup', function () {
       id.search( this.value ).draw();
     });

     const airline = table.columns(1);
     $('#airline').on( 'keyup', function () {
       airline.search( this.value ).draw();
     });

     const origin = table.columns(2);
     $('#origin').on( 'keyup', function () {
       origin.search( this.value ).draw();
     });

     const destination = table.columns(3);
     $('#destination').on( 'keyup', function () {
       destination.search( this.value ).draw();
     });

     const scrape = table.columns(0);
     $('#scrape').on( 'change', function () {
       scrape.search( this.value ).draw();
     });

     var detailRows = [];
     const $table = $('.dataTables-example');
     let format = {
                     'title':{
                       'No':'id',
                       'Airline Id':'airlineId',
                       'Origin':'origin',
                       'Destination':'destination'
                     },
                     'tab':{
                       'Origin':'origin',
                       'Destination':'destination',
                     },
                     'id':'id'
                  }
     this.dataTablesCreator.childCreator({ table  : table,
                                           $table : $table,
                                           button : '.btn-circle',
                                           format : format
                                         })
   });
  }

  closeForm(index){
    $('#edit').attr('href','#'+index);
  }

  addForm(data){
    var trip = data.value.trip == "RT" ? true : false;
    data.value.airlineId = this.scrapeModules.filter(module => module.name === data.value.scrapeModuleId)[0].airlineId;
    var dataCopy = data.value;
    // delete data.value.trip;

    data.value.active = data.value.active == "true" ? true : false;
    data.value.international = data.value.international == "true" ? true : false;
    data.value.popular = data.value.popular == "true" ? true : false;
    // this.dataTablesCreator.add(data.value);
    this.pluto.post('/routes/route',{input:data.value}).subscribe(result => {
      if (typeof result.error !== 'undefined'){
        toastr.error(result.message);
      } else {
        toastr.success('Success Adding');
        $('.modal').find('form').trigger('reset');
      }
    })

    if (trip){
      let destination = dataCopy.destination;
      dataCopy.destination = dataCopy.origin;
      dataCopy.origin = destination;
      // this.dataTablesCreator.add(dataCopy);
      this.pluto.post('/routes/route',{input:dataCopy}).subscribe(result => {
        if (typeof result.error !== 'undefined'){
          toastr.error(result.message);
        } else {
          toastr.success('Success Adding Route');
          $('.modal').find('form').trigger('reset');
        }
      })
    }
  }

  addCross(data){
    // data.value.customUrl = '/1/flight/api/routes/cross-airport/';
    // this.dataTablesCreator.add(data.value);
    this.pluto.post('/routes/route/cross',{input:data.value}).subscribe(result => {
      if (typeof result.error !== 'undefined'){
        toastr.error(result.message);
      } else {
        toastr.success('Success Adding Cross Route');
        $('.modal').find('form').trigger('reset');
      }
    })
  }

  ngOnInit(){
    this.pluto.get('/scrapemodules?filter[where][active]=1',{}).subscribe(data => {
      this.scrapeModules = data;
    })

    this.dtOptions = this.dataTablesCreator.config(this.config);
    jQuery('#date_added,#date_modified').datepicker();
  }

  ngAfterViewInit(){
    $('.instance').click();
  }

}
