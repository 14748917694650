import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';

import { dataTablesCreator } from '../../../../services/datatables.service';
import { plutoRequestService } from '../../../../services/plutoRequest.service';
import { environment } from '../../../../../environments/environment';

declare var jQuery:any;
declare var moment:any;
declare var swal: any;
declare var toastr:any;

@Component({
    selector: 'manage-account',
    templateUrl: 'manage-account-client.template.html',
})
export class manageAccountClientComponent implements OnInit, AfterViewInit {
    @ViewChild(DataTableDirective)
    dtTrigger = new Subject();
    dataShare = {
      firstName: '',
      lastName: '',
    };
    userAlias=[];
    providers;
    dtOptions;
    saldo=[];
    totalSaldo = 0;
    show = {};
    sort = 'ASC';

    constructor(private pluto : plutoRequestService){
      let ang = this;

      ang.pluto.get('/shareproduct/providers',{}).subscribe(providers => {
        ang.providers = providers;
      })

      ang.pluto.get('/shareproduct/accounting/accounts',{}).subscribe(saldo => {
        ang.pluto.get('/shareproduct/accounting/useraccounts',{}).subscribe(accounts => {
          (<any>Object).values(accounts).map(account => {
            (<any>Object).values(saldo).map(saldo => {
              if (account.accountId == saldo.id) {
                saldo.userId = account.userId;
                ang.saldo.push(saldo);
              }
            })
          })

          ang.pluto.get('/shareproduct/permissions',{}).subscribe(permissions => {
            ang.pluto.get('/userApi',{}).subscribe(users => {
              users.result.map((user,key) => {
                user.saldo = 0;
                ang.userAlias.push(user)
                permissions.map(permission => {
                  if (user.group == permission.principalId) {
                    user[permission.providerId] = true;
                  }
                })
                ang.saldo.map(saldo => {
                  if (user.group == saldo.userId) {
                    user.saldo = saldo.balance;
                    ang.userAlias[key] = user;
                    ang.totalSaldo += saldo.balance;
                  }
                })

              })
            })

          })
        })
      })

    }

    dataShareSelected(data) {
      let ang = this;
      ang.dataShare = data;
      // Show Modal Data Share
      $('#dataShare').click();
    }

    sortData() {
      let ang = this;

      function compare(a,b) {
        if (a.saldo < b.saldo)
          return (ang.sort == 'DESC') ? -1 : 1;
        if (a.saldo > b.saldo)
          return (ang.sort == 'DESC') ? 1 : -1;
        return 0;
      }

      ang.userAlias.sort(compare);
      ang.sort = ang.sort == 'DESC' ? 'ASC' : 'DESC';
    }

    activeProvider(user,product){
      let ang = this;
      let url = '/shareproduct/permissions';
      let data = {
        principalId : user.group,
        productId : product.productId,
        providerId : product.name,
      }

      ang.pluto.post(url,{input:data}).subscribe();
    }

    deactiveProvider(user,product){
        var ang = this;
        var url = '/shareproduct/permissions';
        let data = { where : {
          principalId : user.group,
          productId : product.productId,
          providerId : product.name,
        }}
        let urlAdditional = url+'?filter='+JSON.stringify(data);

        ang.pluto.get(urlAdditional,{}).subscribe(data => {
          url += '/' + data[0].id;
          ang.pluto.delete(url,{}).subscribe();
        })
    }

    reRender(): void {
      jQuery(".dataTables-example").dataTable().fnDraw();
    }


    ngOnInit():void {

    }

    ngAfterViewInit(){

    }

}
