import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { sessionService } from '../../services/session.service';

@Component({
    selector: 'login',
    templateUrl: 'login.template.html'
})
export class loginComponent implements OnInit {
  data: any = {};
  loading = false;
  returnUrl: string;
  constructor(private _route: ActivatedRoute, private _router: Router, private _session: sessionService){}

  ngOnInit() {
        // reset login status
    this._session.logout();

        // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
  }

  formSubmit() {
    this.loading = true;
    this._session.login(this.data.user,this.data.password)
        .subscribe(
            data => {
                this._router.navigate(['mainView']);
            },
            error => {
                // this.alertService.error(error);
                this.loading = false;
            });
  }
}
