import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { sessionService } from '../../../services/session.service';
import { PusherService } from '../../../services/pusher.service';
import { logRequestService } from '../../../services/logRequest.service';
import { Router, ActivatedRoute} from '@angular/router';

declare var jQuery:any;
declare var toastr:any;
declare var Howl:any;
declare var moment:any;

@Component({
    selector: 'topnavbar',
    templateUrl: 'topnavbar.template.html'
})
export class TopnavbarComponent {
  public countNotif = 0;
  public lastMinuteNotif = 0;
  public lastHourNotif = 0;
  public lastDayNotif = 0;

  constructor(private router: Router,private _session: sessionService, private pusherService: PusherService, private logService: logRequestService) {
      this.logService.get('/bookWarning',{}).subscribe(data => {
        this.countNotif = data.length;
        if (data.length) {
          this.lastMinuteNotif = moment().diff(moment(data[0].updated_at).add(7, 'hours'), 'minutes');
          this.lastHourNotif = moment().diff(moment(data[0].updated_at).add(7, 'hours'), 'hours');
          this.lastDayNotif = moment().diff(moment(data[0].updated_at).add(7, 'hours'), 'days');
        }
      })
  }

  ngOnInit() {
    var ng = this;
    ng.pusherService.channel.bind('notif-issued', data => {
      ng.countNotif++;

      var sound = new Howl({
        src: ['../../../sound/notif.mp3']
      })
      sound.play()

      toastr.warning(data.notif);

      jQuery('title').text('(' + ng.countNotif + ') Airbinder');
    });
  }

  logout() {
    this._session.logout();
    this.router.navigate(['login']);
  }

  toggleNavigation(): void {
      jQuery("body").toggleClass("mini-navbar");
      smoothlyMenu();
  }


}
