import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { statisticComponent } from "./statistic.component";
import { statisticLogErrorComponent } from "./log-error/st-log-error.component";
import { statisticLogBookingComponent } from "./log-booking/st-log-booking.component";
import { statisticLogSPComponent } from "./log-share-product/log-share-product.component";
import { pipeModule } from '../../components/filter/pipe.module';
import { CurrencyPipe } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
// import { MoneyFormatterDirective } from './money_formatter.directive';
// import { MoneyPipe } from "../../components/filter/money.component";
import { NgDatepickerModule } from 'ng2-datepicker';

@NgModule({
    declarations: [statisticComponent, statisticLogErrorComponent, statisticLogBookingComponent, statisticLogSPComponent],
    imports     : [BrowserModule, ChartsModule, RouterModule, pipeModule, DataTablesModule, ReactiveFormsModule, FormsModule, NgDatepickerModule],
    // providers   : [dataTablesCreator],
    exports     : [statisticComponent, statisticLogErrorComponent, statisticLogBookingComponent, statisticLogSPComponent],
})

export class statisticModule {}
