import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';

// import {Headers, Http, Response,RequestOptions} from '@angular/http';
// import {Observable} from 'rxjs/Observable';
// import 'rxjs/add/operator/map';

import { dataTablesCreator } from '../../../services/datatables.service';
import { plutoRequestService } from '../../../services/plutoRequest.service';

declare var jQuery:any;

@Component({
    selector: 'airports',
    templateUrl: 'airports.template.html',
})
export class airportsComponent implements OnInit, AfterViewInit {
    @ViewChild(DataTableDirective)
    public datatableElement: DataTableDirective;
    dtTrigger = new Subject();
    dataClicked=[];
    dtOptions;

    constructor(private dataTablesCreator : dataTablesCreator, private pluto : plutoRequestService){}

    tableCreator = [
                    {
                      title: 'Airport Name',
                      data: 'name',
                      searchAble: true,
                      render: (data) => `<h3 style="color:#05846b">${data}</h3>`,
                    },
                    {
                      title: 'IATA',
                      data: 'iata',
                      orderable: false,
                      className: 'text-center',
                      render: (data) => `<h4>${data}</h4>`,
                    },
                    {
                      title: 'ICAO',
                      data: 'icao',
                      orderable: false,
                      className: 'text-center',
                      render: (data) => `<h4>${data}</h4>`,
                    },
                    {
                      title: 'Type',
                      data: 'country',
                      render: (data) => data !== 'Indonesia' ? 'International' : 'Local',
                      orderable: true,
                      className: 'text-center'
                    },
                    {
                      title: 'Popular',
                      data: 'popular',
                      render: (data) => data ? '<h4 style="color:#05846b">Yes</h4>' : '<h4 style="color:red">No</h4>',
                      className: 'text-center',
                    },
                    {
                      title: 'Active',
                      data: 'active',
                      render: (data) => data ? '<h4 style="color:#05846b">Yes</h4>' : '<h4 style="color:red">No</h4>',
                      className: 'text-center',
                    },
                    {
                      title: 'Details',
                      data: null,
                      defaultContent: '<button type="button" class="btn btn-primary btn-circle" title="View details"><i class="fa fa-unsorted"></i></button>',
                      orderable: false,
                      className: 'text-center'
                    },
                  ];
    config = {
                api : '/airports',
                clickHandler : this.someClickHandler,
                table : this.tableCreator,
                dataClicked : this.dataClicked
             }

    getInstance(datatableElement: DataTableDirective): void {
    datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
      {
        // this.table = dtInstance;
        const table = dtInstance;

        //  Search By
         const iata = table.columns(1);
         $('#iata').on( 'keyup', function () {
           iata.search( this.value ).draw();
         });

         const icao = table.columns(2);
         $('#icao').on( 'keyup', function () {
           icao.search( this.value ).draw();
         });

         const airport = table.columns(0);
         $('#airport').on( 'keyup', function () {
           airport.search( this.value ).draw();
         });

         const date = table.columns(3);
         $('#date_modified').on( 'keyup', function () {
           date.search( this.value ).draw();
         });

        var detailRows = [];
        const $table = $('.dataTables-example');
        let format = {
                        'title':{
                          'City':'city',
                          'ID Country':'countryId',
                          'Country':'country',
                          'Region':'region'
                        },
                        'tab':{
                          'Increment':'increment',
                          'Altitude':'altitude',
                          'Timezone':'timezone',
                          'DST':'dst'
                        },
                        'id':'name'
                     }
        this.dataTablesCreator.childCreator({ table  : table,
                                              $table : $table,
                                              button : '.btn-circle',
                                              format : format
                                            })
      });

    }

    editForm(index,id){
      let next = index+1;
      $('td:contains('+id+')').val(id).parents('tr').removeClass('clicked').css('background-color','white');
      this.dataClicked.forEach(function(value,index,obj){
            if (value.name == id){
              obj.splice(index, 1);
            }
      })
      if($("#" + next).length == 0) {
        $('#edit').attr('href','#0');
      } else {
        $('#edit').attr('href','#'+next).click();
      }
    }

    closeForm(id){
      $('#edit').attr('href','#0');
      this.dataClicked = this.dataClicked;
    }

    someClickHandler(e,info: any): void {
      let checkIsset = false;
      if (typeof this.dataClicked.length !== 'undefined'){
        checkIsset = this.dataClicked.find( function( data ) {
          return data.name === info.name;
        });
      }
      if (checkIsset) {
        this.dataClicked.forEach(function(value,index,obj){
              if (value.name == info.name){
                obj.splice(index, 1);
              }
        })
        $(e.target).parents('tr').removeClass('clicked').css('background-color','white');
      } else {
        this.dataClicked.push(info);
        $(e.target).parents('tr').addClass('clicked').css('background-color','#1ab3944d');
      }
    }

      ngOnInit():void {

        this.dtOptions = this.dataTablesCreator.config(this.config);

        jQuery('#date_added,#date_modified').datepicker();
      }

      ngAfterViewInit(){
        $('.instance').click();
      }

      // private handleError(error: any): Promise<any> {
      //   console.error('An error occurred', error); // for demo purposes only
      //   return Promise.reject(error.message || error);
      // }
}
