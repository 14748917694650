import { Injectable }     from '@angular/core';
// import {Headers, Http, Response,RequestOptions} from '@angular/http';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';

import { plutoRequestService } from './plutoRequest.service';

declare var jQuery:any;
declare var swal: any;
declare var toastr:any;

const BASE_URL = 'https://api-dev.airbinder.co.id';

@Injectable()

export class dataTablesCreator {
  token:string;
  api:string;
  primaryKey:string;
  private ENV = environment;
  constructor(private pluto : plutoRequestService){
    this.token = pluto.getToken();
  }

  config(opts){
      this.api = opts.api;
      this.primaryKey = opts.primaryKey;
      const defaultClickHandler = this.someClickHandler;
      let configuration = {
        processing: true,
        serverSide: true,
        paging: true,
        searching: true,
        ordering: true,
        info: true,
        search: {regex: true},
        autoWidth: typeof opts.autoWidth !== 'undefined' ? opts.autoWidth : false,
        lengthMenu: [[10, 25, 50, 9999999], [10, 25, 50, "All"]],
        ajax: {
                url: (!!opts.urlApi ? this.ENV[opts.urlApi] : this.ENV.API_URL) + opts.api,
                headers: {
                            'Content-Type':'application/json',
                            'Authorization': 'Bearer ' + this.token,
                          },
                method: typeof opts.method !== 'undefined' ? opts.method : 'GET',
                dataType: 'json',
                // success: function(data) {
                  // console.log(data.result)
                // },
                error: function (xhr, ajaxOptions, thrownError) {
                  swal("Error", xhr.responseJSON, "warning");
                },
              },
        displayLength: typeof opts.displayLength !== 'undefined' ? opts.displayLength : 10,
        paginationType: "full_numbers",
        columns: opts.table,
        aaSorting: [[ 3, 'desc' ]],
        dom: '<"ibox-content"<"row"l<"pull-right"B>><"row"rtip>>',
        buttons: [
          opts.button,
          'copy',
          {
            text: 'Edit',
            key: '1',
            action: function (e, dt, node, config) {
              $('#edit').click();
            }
          },
          {
            text: 'Delete',
            key: '1',
            action: function (e, dt, node, config) {
              $('#delete').click();
            }
          },
          'csv', 'excel', 'pdf', 'print',
        ],
        rowCallback: function(row: Node, data, iDisplayIndex) {
          // Increment Number
          // Notification color
          // if ((data.status == 'issued' && !!!data.issuedBy) || (data.status == 'booking' && !!data.issuedBy) || (data.status == 'issued' && !!!data.printTicket)){
          //   $(row).css('background','#f38c9e')
          // }
          if (data.warning) $(row).css('background','#f38c9e');

          $(row).hover(function(){
            typeof opts.hoverAction !== 'undefined' ? opts.hoverAction(data,row) : $(row).css('background','');
          })

          var info = this.api().page.info();
          var page = info.page;
          var length = info.length;
          var index = (page * length + (iDisplayIndex +1));
          $('td.increment', row).eq(0).html(index);
          // Click Handler
          const self = this;
          $('td', row).unbind('click');

          if ($('td',row).find('.btn-sm')){
            $('td', row).find('.btn-sm').bind('click', (e) => {
              typeof opts.smButton !== 'undefined' ? opts.smButton(data,e.target) : console.log('button clicked');
            })
          }

          $('td', row).bind('click', (e) => {
            if (!$(e.target).hasClass('btn') && opts.dataClicked)
            {
              typeof opts.clickHandler !== 'undefined' ? opts.clickHandler(e,data,opts.primaryKey) : defaultClickHandler(e,data,opts.dataClicked,opts.primaryKey);
            }
          });
          return row;
        },
        initComplete: function () {
          // Code Here
        },
        bDestroy: true,
        responsive: true,

      };

      return configuration;
  }

  childCreator(opts){
    let table  = opts.table;
    let $table = opts.$table;
    let button = opts.button;
    let format = opts.format;
    var detailRows = [];

    $table.on('click', button, ({target}) => {
       const $tr = $(target).closest('tr');
       const row = table.row($tr);
       var data = row.data();

       data.increment = $tr.children('td.increment').text();
       if (row.child.isShown()) {
         $('div.slider', row.child()).slideUp()
         row.child.hide();
         $tr.removeClass('shown');
       } else {
         let class$tr = $tr.attr("class");
         row.child( this.format_child(data,format), class$tr).show();
         $tr.addClass('shown');
         $('div.slider', row.child()).slideDown();
       }
     });

    table.on( 'draw', function () {
        $.each( detailRows, function ( i, id ) {
            $('.btn-view-details').trigger( 'click' );
        });
    });

  }

  format_child(data,opts){
    let title='';
    let tab={'title':'','data':''};
    const divFirst = '<div class="slider" style="display:none;">'+
                        '<div style="float:left;width:50%;">'+
                          '<table border="0" style="padding:0 1em 0 1em; border-collapse: separate;border-spacing: 1em 1em;">';
    Object.keys(opts).forEach(key => {
          switch(key){
            case 'title':
              let index = 1;
              Object.keys(opts[key]).forEach(keys => {
                title += '<tr><th>'+keys+'</th><td>'+eval('data.'+opts[key][keys])+'</td></tr>';
                index++;
              });
              break;
            case 'tab':
              index = 1;
              Object.keys(opts[key]).forEach(keys => {
                let status = index == 1 ? true : false;
                let active = status ? 'active in' : '';
                tab.title += '<li class="'+ active +'">'+
                                '<a data-toggle="tab" href="#'+ keys+eval('data.'+opts.id) +'" aria-expanded="'+ status +'">'+ keys +'</a>'+
                              '</li>';
                tab.data  += '<div id="'+ keys+eval('data.'+opts.id) +'" class="tab-pane fade '+ active +'">'+
                                '</br>'+
                                '<p>'+ eval('data.'+opts[key][keys]) +'</p>'+
                              '</div>';
                index++;
              });
              break;
          }
    });
    let child = divFirst +
                  title +
                    '</table></div>'+
                    '<div class="container" style="float: left;width:50%;">'+
                    '<ul class="nav nav-tabs">'+
                      tab.title+
                    '</ul>'+
                    '<div style="word-wrap: break-word; max-width:600px;" class="tab-content">'+
                      tab.data+
                    '</div>'+
                '</div></div>';
        return child;
  }

  editForm(dataClicked,dataForm,index,id){
    let next = index+1;
    let primaryKey = this.primaryKey;
    // let api = this.api.split('/').slice(0, this.api.split('/').length - 1).join("/") + "/";
    let api = this.api;

    // Hiden Modal Manual
    if (dataClicked !== 'null'){
      jQuery('#'+index).modal('hide');
      $('.modal-backdrop').remove('div');
      if($('#' + next).length == 0) {
        $('#edit').attr('href','#0');
      } else {
        $('#edit').attr('href','#'+next).click();
      }
    }

    // Mergering dataClicked with dataInput
    // dataClicked.forEach(function(value,index,obj){
    //   if (value[primaryKey] == id){
    //     dataFixed = Object.assign({},dataClicked[index],dataForm.value);
    //   }
    // })

    // Get Data Form and process to Pluto
    let editPluto = this.pluto.put(api+'/'+id, {input:dataForm.value});
    editPluto.subscribe(
      suc => {
        if (typeof suc.error !== 'undefined'){
          toastr.error(suc.error.message);
          jQuery('#'+index).modal('show');
        } else {
          toastr.success('Data Saved');

          // Delete dataClicked
          if (dataClicked !== 'null'){
            $('td:contains('+id+')').val(id).parents('tr').removeClass('clicked').css('background-color','white');
            dataClicked.forEach(function(value,index,obj){
              if (value[primaryKey] == id){
                obj.splice(index, 1);
              }
            })
          }
          jQuery(".dataTables-example").dataTable().fnDraw();
        }
      },
      err => {
        toastr.error(err+' : Try Again');
        jQuery('#'+index).modal('show');
      });
  }

  delete(id, data=null){
    let api = this.api.split('/').slice(0, this.api.split('/').length - 1).join("/") + "/";
    let pluto = this.pluto;
    let deletePluto;

    swal({
          title: "Are you sure?",
          text: "Are you sure to delete data ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          // closeOnConfirm: true,
          // closeOnCancel: true
        }).then((result) => {
          if (result) {
            if (Array.isArray(id)){
              id.forEach(function(value,index){
                deletePluto = data !== null ? pluto.delete(api+value.id, {input:JSON.stringify(data)}) : pluto.delete(api+value.id, {null:'null'});
                deletePluto.subscribe(
                  suc => {
                    if (typeof suc.error !== 'undefined'){
                      toastr.error(suc.error.message);
                    } else {
                      toastr.success('Deleted');
                      jQuery(".table").dataTable().fnDraw();
                      // jQuery('.table').DataTable().ajax.reload();
                    }
                  },
                  err => {
                    toastr.error(err);
                  });
                })
              } else {
                deletePluto = data !== null ? pluto.delete(api+id, {input:JSON.stringify(data)}) : pluto.delete(api+id, {null:'null'});
                deletePluto.subscribe(
                  suc => {
                    if (typeof suc.error !== 'undefined'){
                      toastr.error(suc.error.message);
                    } else {
                      toastr.success('Deleted');
                      jQuery(".table").dataTable().fnDraw();
                      // jQuery('.table').DataTable().ajax.reload();
                    }
                  },
                  err => {
                    toastr.error(err);
                  });
              }
            } else if (result.dismiss) {
            } else {

            }
        })
  }

  add(data){
    let api = this.api.split('/').slice(0, this.api.split('/').length - 1).join("/") + "/";
    let method = 'post';
    let callBack = 'false';
    let catchData = 'subscribe';
    if (typeof data.customUrl !== 'undefined'){ api = data.customUrl; delete data.customUrl;}
    if (typeof data.method !== 'undefined'){ method = data.method; delete data.method;}
    if (typeof data.callBack !== 'undefined') { callBack = data.callBack; delete data.callBack;}
    if (typeof data.catchData !== 'undefined') { catchData = data.catchData; delete data.catchData;}
    let addPluto = this.pluto[method](api, {input:JSON.stringify(data)});

    return addPluto[catchData](
      suc => {
        $('.close').click().click();
        jQuery(".dataTables-example").dataTable().fnDraw();
        if (typeof suc.error !== 'undefined'){
          toastr.error(suc.error.message);
        } else {
          toastr.success('Success Adding');
          $('.modal').find('form').trigger('reset');
        }
        if (typeof suc[callBack] !== 'undefined'){
          return suc[callBack];
        }
      },
      err => {
        toastr.error(err);
      });

  }

  someClickHandler(e,info: any,dataClicked,primaryKey): void {
    let checkIsset = false;
    if (typeof dataClicked.length !== 'undefined'){
      checkIsset = dataClicked.find( function( data ) {
        return data[primaryKey] === info[primaryKey];
      });
    }
    if (checkIsset) {
      dataClicked.forEach(function(value,index,obj){
            if (value[primaryKey] == info[primaryKey]){
              obj.splice(index, 1);
            }
      })
      $(e.target).parents('tr').removeClass('clicked').css('background-color','white');
    } else {
      dataClicked.push(info);
      $(e.target).parents('tr').addClass('clicked').css('background-color','#1ab3944d');
    }
  }
}
