import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { dataTablesCreator } from '../../../../services/datatables.service';

import { plutoRequestService } from '../../../../services/plutoRequest.service';
import { logRequestService } from '../../../../services/logRequest.service';
import { PusherService } from '../../../../services/pusher.service';

declare var jQuery:any;
declare var moment:any;

@Component({
    selector: 'report-booking',
    templateUrl: 'report-booking.template.html'
})
export class reportBookingComponent {
  @ViewChild(DataTableDirective)
  public datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  dataClicked=[];
  dtOptions;
  userJson;
  userAlias = [];
  idClient;
  scrapeModules = [];
  min: '2018-07-06';
  max: '2018-07-07';
  public countNotif = 0;
  public lastMinuteNotif = 0;
  public lastHourNotif = 0;
  public lastDayNotif = 0;

  constructor(private dataTablesCreator: dataTablesCreator, private pusherService: PusherService, private pluto: plutoRequestService, private log: logRequestService){
    let ang = this;

    ang.pluto.get('/userApi',{}).subscribe(data => {
      ang.userJson = data.result;
      data.result.map(user => {
        ang.userAlias[user.group] = user.username;
      })
    })

    ang.pluto.get('/scrapemodules?filter[where][active]=1',{}).subscribe(data => {
      ang.scrapeModules = data;
    })

    ang.idClient = ang.pluto.getIdClient();

    ang.pusherService.channel.bind('notif-issued', data => {
      $('#code').keyup()
    });

  }

  tableCreator = [{
                    title: 'Booking Code',
                    data: 'bookingCode',
                    searchAble: true,
                    render: (data) => {return `<h2><b>${data}</b></h2>`},
                    className: 'text-center',
                  },
                  {
                    title: 'Client',
                    data: 'userId',
                    render: (data) => data ? this.userAlias[data] : '',
                    searchAble: true,
                    className: 'text-center',
                  },
                  {
                    title: 'Maskapai',
                    data: 'airline',
                    searchAble: true,
                    render: (data) => data ? `<b style="text-transform:capitalize;"> ${data} </b>` : '',
                    className: 'text-center'
                  },
                  {
                    title: 'Date',
                    data: 'updated_at',
                    render: (data) => {return `${moment(data).add(7, 'hours').format('DD MMMM YYYY HH:mm')}`},
                  },
                  {
                    title: 'Status',
                    data: 'status',
                    render: (data) => `<b style="text-transform:capitalize;"> ${data} </b>`,
                    className: 'text-center'
                  },
                  {
                    title: 'Print Ticket',
                    data: 'printTicket',
                    render: (data) => data ? '<span class="label label-primary">Yes</span>' : '<span class="label label-danger">No</span>',
                    className: 'text-center'
                  },
                  {
                    title: 'Ticket Number',
                    data: 'ticket_number',
                    render: (data) => data ? '<span class="label label-primary">Yes</span>' : '<span class="label label-danger">No</span>',
                    className: 'text-center'
                  },
                  {
                    title: 'Issued By',
                    data: 'issuedBy',
                    render: (data) => data ? `<h3 style="color:green"> ${data} </h3>` : '',
                    className: 'text-center'
                  },
                  {
                    title: 'Warning',
                    data: 'warning',
                    visible: false,
                  },
                ];
  config = {
              api : '/reportBookFlight',
              // urlApi : 'API_LOG_URL',
              table : this.tableCreator,
              hoverAction: this.markAsRead,
              primaryKey : 'id',
              log: this.log
           }

  getInstance(datatableElement: DataTableDirective): void {
  datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
   {
     const table = dtInstance;
     let ang = this;

    //  Search By
     const id = table.columns(0);
     $('#code').on( 'keyup', function () {
       id.search( this.value.toUpperCase() ).draw();
     });

     const client = table.columns(1);
     $('#scrapeModule').on( 'change', function () {
       client.search( this.value ).draw();
     });

     const maskapai = table.columns(2);
     $('#maskapai').on( 'change', function () {
       maskapai.search( this.value ).draw();
     });

     const date = table.columns(3);
     $('#date').on( 'keyup', function () {
       // date.search( '2018-07-06' ).draw();
     });

     const status = table.columns(4);
     $('#status').on( 'change', function () {
       status.search( this.value ).draw();
     });

     const warning = table.columns(8);
     $('#warning').on( 'change', function () {
       warning.search( this.value ).draw();
     });

     if(ang.idClient) {
       client.search( ang.idClient ).draw();
       client.visible(false)
     }


     var detailRows = [];
     const $table = $('.dataTables-example');


   });
  }

  editForm(data,index,id){
    this.dataTablesCreator.editForm(this.dataClicked,data,index,id);
  }

  closeForm(index){
    $('#edit').attr('href','#'+index);
  }

  deleteForm(){
    let dataTablesCreator = this.dataTablesCreator;
    dataTablesCreator.delete(this.dataClicked);
    this.dataClicked.forEach(function(value,index,obj){
      $('td:contains('+value.id+')').val(value.id).parents('tr').removeClass('clicked').css('background-color','white');
    })
  }

  addForm(data){
    let trip = data.value.trip == "RT" ? true : false;
    delete data.value.trip;
    var dataCopy = data.value;
    data.value.active = data.value.active == "true" ? true : false;
    data.value.international = data.value.international == "true" ? true : false;
    data.value.popular = data.value.popular == "true" ? true : false;
    this.dataTablesCreator.add(data.value);
    if (trip){
      let destination = dataCopy.destination;
      dataCopy.destination = dataCopy.origin;
      dataCopy.origin = destination;
      this.dataTablesCreator.add(dataCopy);
    }
  }

  addCross(data){
    data.value.customUrl = '/1/flight/api/routes/cross-airport/';
    this.dataTablesCreator.add(data.value);
  }

  markAsRead(data,row) {
    if (data.warning) {
      data.warning = false;
      this.log.post('/bookAirline', {input: data}).subscribe(result => {
        jQuery(row).css('background','');
        let notifCount = parseInt(jQuery('.notif-count').text())-1;
        if (notifCount > 0) {
          jQuery('.notif-count').text(notifCount);
          jQuery('title').text('(' + notifCount + ') Airbinder');
        } else {
          jQuery('.notif-count').text('');
          jQuery('title').text('Airbinder');
        }
      })
    }
  }

  ngOnInit(): void {
    let ang = this;

    ang.idClient = ang.pluto.getIdClient();
    // if (ang.idClient) {
      // ang.tableCreator.splice(ang.tableCreator.findIndex(item => item.title === "Client"), 1)
    // }
    ang.dtOptions = ang.dataTablesCreator.config(ang.config);
  }

  ngAfterViewInit(){
    let ang = this;

    $('.instance').click();
    // ang.dtTrigger.next();
  }

  ngOnDestroy(): void {
    let ang = this;

    // Do not forget to unsubscribe the event
    // ang.dtTrigger.unsubscribe();
  }

}
