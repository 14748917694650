import { Component } from '@angular/core';

declare var jQuery:any;

@Component({
    selector: 'header-share',
    templateUrl: 'share.template.html'
})

export class shareComponent {}
